import { reformatToThousands } from '../../../../utils/dashboard/formatters';
import vectorIcon from '../../../../../../public/images/vector.png';

export const SplitSummary = (props: {
  name?: string;
  value?: number | string;
  subValues?: { title: string; value: number | string }[];
  options?: {
    color?: string;
  };
}) => {
  if (!props.name || props.value === undefined) {
    return <p>No data provided for the reporting month</p>;
  }

  const displayValue =
    typeof props.value === 'number'
      ? reformatToThousands(Math.abs(props.value))
      : props.value;

  const valueColor = props.options?.color || '#000';

  return (
    <div className={'split-summary-card'}>
      <div className={'main-value-section'}>
        <p className={'main-title'}>{props.name}</p>
        <p className={'main-value'} style={{ color: valueColor }}>
          {displayValue}
        </p>
      </div>
      <img src={vectorIcon} alt="vector icon" className={'vector-icon'} />
      <div className={'sub-values-section'}>
        {props.subValues &&
          props.subValues.map((sub, index) => (
            <div key={index} className={'sub-value'}>
              <p className={'sub-value-amount'} style={{ color: valueColor }}>
                {typeof sub.value === 'number'
                  ? reformatToThousands(Math.abs(sub.value))
                  : sub.value}
              </p>
              <p className={'sub-value-title'}>{sub.title}</p>
            </div>
          ))}
      </div>
    </div>
  );
};
