import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ManagerHttpMappingService } from '../../tools/manager-api/manager-http-mapping';
import {
  IMappingPagination,
  Mapping,
  MappingMasterList,
  MappingSource,
  SourceType,
} from '../../tools/manager-api/types/mapping';
import { ToasterContext } from '../../context/toaster.context';

export const useMapping = () => {
  const { companyId } = useParams();
  const ctx = useContext(ToasterContext);
  const [fuelRecommendations, setFuelRecommendations] = useState<
    MappingMasterList[]
  >([]);
  const [integrations, setIntegrations] = useState<MappingSource[] | null>(
    null
  );
  const [integrationMappings, setIntegrationMappings] = useState<
    Mapping[] | null
  >(null);
  const [allMasterListData, setAllMasterListData] = useState<
    MappingMasterList[]
  >([]);
  const [totalMasterCount, setTotalMasterCount] = useState(0);
  const [totalMappingCount, setTotalMappingCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [chooseBaseModalActive, setChooseBaseModalActive] =
    useState<boolean>(false);
  const [chooseBaseModalShowed, setChooseBaseModalShowed] =
    useState<boolean>(false);
  const fetchIntegrationsAndMasterLists = useCallback(
    async (sourceType: SourceType, pagination: IMappingPagination) => {
      setLoading(true);
      try {
        const { data: integrationsData } =
          await ManagerHttpMappingService.getAvailableIntegrations(
            Number(companyId),
            sourceType
          );

        const {
          data: { result: masterLists, total },
        } = await ManagerHttpMappingService.filterMasterList(
          Number(companyId),
          sourceType,
          {
            page: pagination.page,
            limit: pagination.limit,
            search: pagination.search,
            sorting: pagination.sorting,
          }
        );

        setIntegrations(integrationsData);
        setFuelRecommendations(masterLists);
        setTotalMasterCount(total);
        if (
          !chooseBaseModalShowed &&
          !masterLists.length &&
          !allMasterListData.length
        ) {
          setChooseBaseModalActive(true);
          setChooseBaseModalShowed(true);
        }
      } catch {
        ctx.updateToaster({
          content: 'Failed to fetch master list and integrations',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [companyId, ctx, chooseBaseModalShowed, allMasterListData.length]
  );
  const fetchMappings = useCallback(
    async (
      sourceType: SourceType,
      activeIntegration: number | null,
      pagination: IMappingPagination
    ) => {
      if (activeIntegration === null) return;
      setLoading(true);
      try {
        const {
          data: { result: mappings, total },
        } = await ManagerHttpMappingService.getMappingList(
          Number(companyId),
          sourceType,
          activeIntegration,
          {
            page: pagination.page,
            limit: pagination.limit,
            search: pagination.search,
            sorting: pagination.sorting,
          }
        );

        setIntegrationMappings(mappings);
        setTotalMappingCount(total);
      } catch {
        ctx.updateToaster({
          content: 'Failed to fetch mappings',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [companyId, ctx]
  );

  const fetchAllMasterListData = useCallback(
    async (sourceType: SourceType) => {
      try {
        const { data } = await ManagerHttpMappingService.getMasterList(
          Number(companyId),
          sourceType
        );
        setAllMasterListData(data);
      } catch {
        ctx.updateToaster({
          content: 'Failed to fetch master list',
          isError: true,
        });
      }
    },
    [companyId, ctx]
  );
  return {
    fuelRecommendations,
    integrations,
    integrationMappings,
    allMasterListData,
    totalMasterCount,
    totalMappingCount,
    loading,
    chooseBaseModalActive,
    fetchIntegrationsAndMasterLists,
    fetchMappings,
    fetchAllMasterListData,
    setLoading,
    setChooseBaseModalActive,
    setIntegrationMappings,
  };
};
