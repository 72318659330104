import {
  DashboardCard,
  HistogramCard,
  HistogramProperties,
  PieChartCard,
  RaceChartCard,
  StackedBarCard,
  SummaryCard,
  TableCard,
  LineChartCard,
  DynamicTableCard,
  CategoriesTableCard,
  ComparisonSummaryCard,
  BalanceSummaryCard,
  TopCategoriesBalanceCard,
  WaterfallCard,
  CombinedChartCard,
  AreaChartCard,
  BarChartByCategoryCard,
  SplitSummaryCard,
  HeatmapChartCard,
  UpdatedStackedChartCard,
} from './dashboard-card';
import {
  CategoriesBalanceTableDataset,
  DynamicTableDataset,
  HistogramDataset,
  OpexCogsTableDataset,
  OutflowCategoriesTableDataset,
  PieChartDataset,
} from './interfaces/dashboard-card.type';

interface CardFactory {
  createCard<T, K>(
    type: string,
    identifier: string,
    layout: {
      width: number;
      xPosition: number;
      yPosition: number;
      height: number;
    }
  ): DashboardCard<T, K>;
}
export class ConcreteCardFactory implements CardFactory {
  createCard<T, K>(
    type: string,
    identifier: string,
    layout: {
      width: number;
      xPosition: number;
      yPosition: number;
      height: number;
    },
    data?: T,
    options?: K
  ): DashboardCard<T, K> {
    switch (type) {
      case 'HistogramCard':
        return new HistogramCard(
          identifier,
          layout,
          data as HistogramProperties,
          options as { colors: string; enableLine: boolean; percents?: boolean }
        );

      case 'SummaryCard':
        return new SummaryCard(
          identifier,
          layout,
          data as {
            name: string;
            dataset: {
              value: number;
              prevMonthData: number;
              year: number;
              month: number;
              signType: 'percent' | 'value';
            };
          }
        );

      case 'ComparisonSummaryCard':
        return new ComparisonSummaryCard(
          identifier,
          layout,
          data as {
            dataset: {
              firstValue: number;
              secondValue: number;
              year: number;
              month: number;
            };
          },
          options as { firstName: string; secondName: string }
        );

      case 'BalanceSummaryCard':
        return new BalanceSummaryCard(
          identifier,
          layout,
          data as {
            name: string;
            dataset: {
              value: number | string;
            };
          }
        );

      case 'SplitSummaryCard':
        return new SplitSummaryCard(
          identifier,
          layout,
          data as {
            name: string;
            dataset: {
              value: number | string;
              subValues?: { title: string; value: number | string }[];
            };
          },
          options as { color: string }
        );

      case 'RaceChart':
        return new RaceChartCard(
          identifier,
          layout,
          data as {
            name: string;
            dataset: { data: any[]; keys: string[]; indexBy: string };
          }
        );

      case 'StackedBarCard':
        return new StackedBarCard(
          identifier,
          layout,
          data as { name: string; dataset: HistogramDataset },
          options as { colors?: string[]; percents?: boolean }
        );

      case 'BarChartByCategoryCard':
        return new BarChartByCategoryCard(
          identifier,
          layout,
          data as { name: string; dataset: HistogramDataset },
          options as { colors?: string[]; percents?: boolean }
        );

      case 'TableCard':
        return new TableCard(
          identifier,
          layout,
          data as { name: string; dataset: OpexCogsTableDataset }
        );
      case 'CategoriesTableCard':
        return new CategoriesTableCard(
          identifier,
          layout,
          data as { name: string; dataset: OutflowCategoriesTableDataset }
        );
      case 'TopCategoriesBalanceCard': {
        return new TopCategoriesBalanceCard(
          identifier,
          layout,
          data as { name: string; dataset: CategoriesBalanceTableDataset }
        );
      }
      case 'PieChartCard':
        return new PieChartCard(
          identifier,
          layout,
          data as { name: string; dataset: PieChartDataset }
        );

      case 'LineChartCard':
        return new LineChartCard(
          identifier,
          layout,
          data as { name: string; dataset: any },
          options as { colors?: string[] }
        );
      case 'AreaChartCard':
        return new AreaChartCard(
          identifier,
          layout,
          data as { name: string; dataset: any },
          options as { colors?: string[] }
        );
      case 'DynamicTableCard':
        return new DynamicTableCard(
          identifier,
          layout,
          data as { name: string; dataset: DynamicTableDataset }
        );
      case 'WaterfallCard':
        return new WaterfallCard(identifier, layout, data, options);
      case 'CombinedChartCard':
        return new CombinedChartCard(identifier, layout, data, options);
      case 'HeatmapChartCard':
        return new HeatmapChartCard(identifier, layout, data, options);
      case 'UpdatedStackedChartCard':
        return new UpdatedStackedChartCard(identifier, layout, data, options);
      default:
        throw new Error(`Card type ${type} is not recognized.`);
    }
  }
}
