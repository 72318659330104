import React, { useRef, useState } from 'react';
import { RoleContext } from '../../../context/role.context';
import { useContext } from 'react';
import './header.style.css';
import { useManagerInfo } from '../../../hooks/manager/manager-info.hook';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useDashboard } from '../../../pages/bootstrap/hooks/dashboard.hook';
import { useClickOutside } from '../../../hooks/shared/click-outside.hook';

export const HeaderHoc = () => {
  const location = useLocation();
  const path = location.pathname;

  const pathToNameMap = new Map([
    ['dashboard', 'DASHBOARD'],
    ['financial-reports', 'FINANCIAL-REPORTS'],
    ['data-sources', 'DATA SOURCES'],
    ['mapping', 'MAPPING'],
    ['metrics', 'METRICS MANAGEMENT'],
    ['fin-groups', 'FINANCIAL GROUPS'],
  ]);

  const splitPath = path.split('/');
  const pathForMap = splitPath[splitPath.length - 1];

  const { role, userType } = useContext(RoleContext);

  if (!role || !userType) return null;

  if (!['MANAGER', 'HUBSPOT'].includes(userType)) return null;

  return (
    <HeaderComponent
      userType={userType}
      pageName={pathToNameMap.get(pathForMap) || 'HOME'}
    />
  );
};

interface HeaderMenuProps {
  icon?: string;
  name: string;
  dropdownOptions: Array<{
    name: string;
    link?: string;
    action?: () => void;
  }>;
}

const HeaderMenu = (props: HeaderMenuProps) => {
  const { name, dropdownOptions, icon } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useClickOutside(menuRef, () => setIsMenuOpen(false));

  const getMenuImage = () => {
    if (!icon) {
      return <div className={'header-menu-logo'}>{name[0]}</div>;
    }
    return <img className={'header-menu-logo'} src={icon} />;
  };

  const menuClassnames = classNames('header-menu-dropdown-content', {
    open: isMenuOpen,
  });

  return (
    <div onClick={toggleMenu} className={'header-menu'} ref={menuRef}>
      {getMenuImage()}
      <div className={'header-menu-name'}>{name}</div>
      <button className={'header-menu-dropdown-button'}>
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z"
            fill="#5F6368"
          />
        </svg>
      </button>
      <div className={menuClassnames}>
        {dropdownOptions.map((option, index) => {
          if (option.action) {
            return (
              <a
                key={index}
                onClick={(e: any) => {
                  e.preventDefault();
                  option.action && option.action();
                }}
              >
                {option.name}
              </a>
            );
          }
          return (
            <a key={index} href={option.link}>
              {option.name}
            </a>
          );
        })}
      </div>
    </div>
  );
};

const UserHeaderMenu = () => {
  const { userData } = useDashboard();
  const company = userData?.clientCompanies[0];
  const options = [
    { name: 'Terms', link: 'https://fuelfinance.me/terms-of-service' },
    {
      name: 'Privacy',
      link: 'https://drive.google.com/file/d/1MRNGp9YXCOvnXFHBik7IZlqalU3PLroO/view',
    },
    {
      name: 'Contact us',
      link: 'contact-us',
    },
    {
      name: 'Report a bug',
      link: 'report-bug',
    },
    {
      name: 'Log out',
      link: 'logout',
      action: () => {
        localStorage.clear();
        window.location.href = '/login';
      },
    },
  ];
  return (
    <HeaderMenu
      name={company?.name || 'Company'}
      dropdownOptions={options}
      icon={company?.imageUrl}
    />
  );
};

const ManagerHeaderMenu = () => {
  const { managerUserName, managerUserImage } = useManagerInfo();

  const image = managerUserImage || 'https://via.placeholder.com/150';
  const name = managerUserName || 'Manager';

  const options = [
    {
      name: 'Report a bug',
      link: 'report-bug',
    },
    {
      name: 'Log out',
      link: 'logout',
      action: () => {
        localStorage.clear();
        window.location.href = '/login';
      },
    },
  ];
  return <HeaderMenu icon={image} name={name} dropdownOptions={options} />;
};

export const HeaderComponent = ({
  userType,
  pageName,
}: {
  userType: string;
  pageName: string;
}) => {
  if (!userType) return null;

  return (
    <div className={'updated-header'}>
      <span className={'updated-header-name'}>{pageName}</span>
      {userType === 'MANAGER' ? <ManagerHeaderMenu /> : <UserHeaderMenu />}
    </div>
  );
};
