import {
  HistogramDataset,
  PlDashboardDataType,
  SummaryDataset,
} from '../interfaces/dashboard-card.type';
import { DateTime } from 'luxon';
import { reverseSign } from '../../../../utils/dashboard/formatters';
import {
  DashboardDataItem,
  DashboardDataItemWithCategory,
} from '../interfaces/dashboard-data-item.interface';
import { PlDashboardData } from '../interfaces/pl-dashboard-data.interface';
import { getLastClosedMonth, getLongestDateRange } from '../utils';

export class PlDashboardDataManager {
  private date: string | null = null;
  private startDate: Date | null = null;
  private endDate: Date | null = null;
  private methodMap: Map<PlDashboardDataType, any>;

  constructor(private readonly dashboardData: PlDashboardData) {
    this.methodMap = new Map<PlDashboardDataType, any>([
      [
        PlDashboardDataType.PROJECTED_ACTUAL_REVENUE,
        this.getRevenueProjectedActual,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_NET_PROFIT,
        this.getNetProfitProjectedActual,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_REVENUE_STREAMS_BREAKDOWN,
        this.getRevenueBreakdownActualProjected,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_REVENUE_GROWTH_PERCENT,
        this.getRevenueGrowthPercent,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_REVENUE_GROWTH_SUM,
        this.getRevenueGrowthSum,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_GROSS_PROFIT_MARGIN,
        this.getGrossProfitMarginProjectedActual,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_COGS,
        this.getCostOfSalesProjectedActual,
      ],
      [PlDashboardDataType.REVENUE_COGS_GROWTH, this.getRevenueCogsGrowth],
      [PlDashboardDataType.OPEX_SUMMARY, this.getOpexSummary],
      [PlDashboardDataType.REVENUE_OPEX_GROWTH, this.getRevenueOpexGrowth],
      [PlDashboardDataType.EBITDA, this.getEbitda],
      [PlDashboardDataType.NET_PROFIT_MARGIN, this.getNetProfitMargin],
      [PlDashboardDataType.PL_WATERFALL, this.getPlWaterfallData],
    ]);
  }

  public setDate(date: string) {
    this.date = date;
  }

  public setDateRange(startDate: Date | null, endDate: Date | null) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  public getHeaderData(key: keyof PlDashboardData) {
    const data = this.dashboardData[key];
    const { month: targetMonth, year: targetYear } = getLastClosedMonth(
      this.endDate
    );
    const monthData = this.getDateFormMonthData(data).find(
      (d) => d.month === targetMonth && d.year === targetYear
    );

    const prevMonthData = data.find(
      (item) => item.year === targetYear && item.month === targetMonth - 1
    );

    if (!monthData) {
      return {
        month: targetMonth,
        year: targetYear,
        monthData: {
          value: 0,
        },
        prevMonthData: {
          value: prevMonthData?.value || 0,
        },
      };
    }

    return {
      month: monthData.month || 0,
      year: monthData.year,
      monthData: {
        value: monthData.value || 0,
      },
      prevMonthData: {
        value: prevMonthData?.value || 0,
      },
    };
  }

  private getProjectedActualData = (
    actualMetrics: DashboardDataItem[],
    projectedMetrics: DashboardDataItem[],
    projectedLabel: string,
    actualLabel: string,
    xAxis = false
  ): HistogramDataset => {
    const actualData = this.getDateFormMonthData(actualMetrics);

    const actualVsProjected = actualData.map((actualMetric) => {
      const { year, month, value: actualValue } = actualMetric;
      const projectedMetric = projectedMetrics.find(
        (projectedMetric) =>
          projectedMetric.year === year && projectedMetric.month === month
      );

      return {
        date: `${year}-${month}`,
        [actualLabel]: actualValue,
        [projectedLabel]: reverseSign(projectedMetric?.value || 0),
      };
    });

    const nonZeroKeys = [actualLabel, projectedLabel].filter((key) =>
      actualVsProjected.some((entry: any) => entry[key] !== 0)
    );

    return {
      data: actualVsProjected,
      keys: nonZeroKeys,
      indexBy: 'date',
      xAxis,
    };
  };

  public getRevenueProjectedActual = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.revenueActualMetrics,
      this.dashboardData.revenueProjectedMetrics,
      'projected, $',
      'actual, $'
    );
  };

  public getNetProfitProjectedActual = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.netProfitActualMetrics,
      this.dashboardData.netProfitProjectedMetrics,
      'projected, $',
      'actual, $',
      true
    );
  };

  public getGrossProfitMarginProjectedActual = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.grossProfitMarginActualMetrics,
      this.dashboardData.grossProfitMarginProjectedMetrics,
      'projected gross profit, %',
      'actual gross profit, %'
    );
  };

  public getCostOfSalesProjectedActual = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.costOfSalesActualMetrics.map((data) => ({
        ...data,
        value: reverseSign(data.value),
      })),
      this.dashboardData.costOfSalesProjectedMetrics,
      'Projected COGS, $',
      'Actual COGS, $'
    );
  };

  public getRevenueBreakdownActualProjected = () => {
    const { revenueByCategoriesMetrics } = this.dashboardData;

    const filteredRevenueByCategoriesMetrics = this.getDateFormMonthData(
      revenueByCategoriesMetrics
    ) as DashboardDataItemWithCategory[];

    const dateRange = getLongestDateRange([filteredRevenueByCategoriesMetrics]);

    const formattedCategoryRevenueByDate = [];

    let current = dateRange.start;

    while (current <= dateRange.end) {
      const year = current.year;
      const month = current.month;

      const revenueForCurrentMonth = filteredRevenueByCategoriesMetrics.filter(
        (item: DashboardDataItemWithCategory) =>
          item.year === year && item.month === month
      ) as DashboardDataItemWithCategory[];

      const formattedEntry: { [key: string]: any } = {
        date: `${year}-${month}`,
      };

      revenueForCurrentMonth.forEach((item) => {
        formattedEntry[item.category] = item.value;
      });

      formattedCategoryRevenueByDate.push(formattedEntry);

      current = current?.plus({ months: 1 });
    }

    const allCategories = Array.from(
      new Set(
        filteredRevenueByCategoriesMetrics.map(
          (item: DashboardDataItemWithCategory) => item.category
        )
      )
    );

    return {
      keys: allCategories,
      indexBy: 'date',
      data: formattedCategoryRevenueByDate,
    };
  };

  public getGrowthPercentData = (
    actualMetrics: DashboardDataItem[],
    projectedMetrics: DashboardDataItem[],
    actualLabel: string,
    projectedLabel: string
  ) => {
    const processMetrics = (metrics: DashboardDataItem[]) => {
      return this.getDateFormMonthData(metrics).map(
        ({ year, month, value }) => ({
          x: DateTime.local(year, month, 1).toFormat('yyyy-MM'),
          y: parseFloat((value / 100).toFixed(2)), // Ensure `y` is a number
        })
      );
    };

    const fillMissingDates = (
      data: { x: string; y: number }[],
      allMonths: string[]
    ) => {
      const dataMap = new Map(data.map((item) => [item.x, item.y]));

      return allMonths.map((month) => ({
        x: month,
        y: dataMap.get(month) ?? 0,
      }));
    };

    const actualData = processMetrics(actualMetrics);
    const projectedData =
      projectedMetrics.length > 0 ? processMetrics(projectedMetrics) : [];

    const allMonths = Array.from(
      new Set([...actualData, ...projectedData].map((item) => item.x))
    ).sort(
      (a, b) =>
        DateTime.fromFormat(a, 'yyyy-MM').toMillis() -
        DateTime.fromFormat(b, 'yyyy-MM').toMillis()
    );

    const filledActualData = fillMissingDates(actualData, allMonths);
    const filledProjectedData = fillMissingDates(projectedData, allMonths);

    const isAllZero = (data: { x: string; y: number }[]) =>
      data.every((point) => point.y === 0);

    const filteredWithValues = [
      projectedMetrics.length > 0 && !isAllZero(filledProjectedData)
        ? { key: projectedLabel, data: filledProjectedData }
        : null,
      !isAllZero(filledActualData)
        ? { key: actualLabel, data: filledActualData }
        : null,
    ].filter((item) => item !== null);

    return {
      dataset: filteredWithValues,
      curve: 'natural',
      enablePoints: false,
    };
  };

  public getRevenueGrowthPercent = () => {
    return this.getGrowthPercentData(
      this.dashboardData.revenueGrowthActualMetricsAsPercent,
      this.dashboardData.revenueGrowthProjectedMetricsAsPercent,
      'actual revenue growth, %',
      'projected revenue growth, %'
    );
  };

  public getRevenueGrowthSum = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.revenueGrowthActualMetricsAsSum,
      this.dashboardData.revenueGrowthProjectedMetricsAsSum,
      'projected revenue growth, $',
      'actual revenue growth, $'
    );
  };

  public getRevenueCogsGrowth = () => {
    return this.getGrowthPercentData(
      this.dashboardData.revenueGrowthActualMetricsAsPercent,
      this.dashboardData.cogsGrowthMetrics,
      'Revenue Growth, %',
      'COGS Growth, %'
    );
  };

  public getRevenueOpexGrowth = () => {
    return this.getGrowthPercentData(
      this.dashboardData.revenueGrowthActualMetricsAsPercent,
      this.dashboardData.opexGrowthMetrics,
      'Revenue Growth, %',
      'OPEX Growth, %'
    );
  };

  public getOpexSummary = (): SummaryDataset | null => {
    return this.getSummaryData(PlDashboardDataType.OPEX_SUMMARY);
  };

  private getSummaryData(
    type: PlDashboardDataType,
    signType: 'percent' | 'value' = 'value'
  ): SummaryDataset | null {
    const map = new Map<PlDashboardDataType, keyof PlDashboardData>([
      [PlDashboardDataType.OPEX_SUMMARY, 'opexActualMetrics'],
    ]);

    if (!map.has(type)) {
      console.warn(`No data available for type: ${type}`);
      return null;
    }

    const result = this.getHeaderData(map.get(type) as keyof PlDashboardData);

    return {
      value: result.monthData.value || 0,
      prevMonthData: result.prevMonthData.value || 0,
      year: result?.year || 0,
      month: result?.month || 0,
      signType,
    };
  }

  public getEbitda = () => {
    return this.getHistogramSingleData('ebitdaActualMetrics', 'EBITDA, $');
  };

  public getNetProfitMargin = () => {
    const { netProfitMarginMetrics, netProfitActualMetrics } =
      this.dashboardData;

    const filteredNetProfitActualMetrics = this.getDateFormMonthData(
      netProfitActualMetrics
    );
    const filteredNetProfitMarginMetrics = this.getDateFormMonthData(
      netProfitMarginMetrics
    );

    const marginData = filteredNetProfitActualMetrics.map((metric) => {
      const { year, month, value } = metric;

      return {
        date: DateTime.local(year, month, 1).toFormat('yyyy-MM'),
        'net profit, $': value,
      };
    });

    const marginDataWithLine = marginData.map((item) => {
      return {
        ...item,
        'net profit, $': item['net profit, $'],
        'net profit margin, %':
          filteredNetProfitMarginMetrics.find(
            (metric) => item.date === this.processDataItemWithDate(metric)
          )?.value || 0,
      };
    });

    return {
      data: marginDataWithLine,
      keys: ['net profit, $', 'net profit margin, %'],
    };
  };

  private getHistogramSingleData(
    keyFrom: keyof PlDashboardData,
    keyTo: string,
    reverse = false
  ) {
    const data = this.dashboardData[keyFrom];
    const filteredData = this.getDateFormMonthData(data);

    const remappedData = filteredData.map((dataItem) => {
      return {
        date: this.processDataItemWithDate(dataItem),
        [keyTo]: reverse ? reverseSign(dataItem.value) : dataItem.value,
      };
    });

    return {
      data: remappedData,
      keys: [keyTo],
      indexBy: 'date',
    };
  }

  public getPlWaterfallData = () => {
    const revenueData = this.getHeaderData('revenueActualMetrics');
    const otherIncomeData = this.getHeaderData('otherIncomeActualMetrics');
    const costOfSalesData = this.getHeaderData('costOfSalesActualMetrics');
    const opexData = this.getHeaderData('opexActualMetrics');
    const otherExpensesData = this.getHeaderData('otherExpensesActualMetrics');
    const grossProfitData = this.getHeaderData('grossProfitActualMetrics');
    const ebitdaData = this.getHeaderData('ebitdaActualMetrics');
    const netProfitData = this.getHeaderData('netProfitActualMetrics');

    return {
      data: [
        {
          name: 'Revenue',
          uv: revenueData.monthData.value,
          pv: 0,
          amt: revenueData.monthData.value,
        },
        {
          name: 'Cogs',
          uv: costOfSalesData.monthData.value,
          pv: revenueData.monthData.value,
        },
        {
          name: 'Gross profit',
          uv: grossProfitData.monthData.value,
          pv: 0,
        },
        {
          name: 'Opex',
          uv: opexData.monthData.value,
          pv: grossProfitData.monthData.value,
        },
        {
          name: 'Ebitda',
          uv: ebitdaData.monthData.value,
          pv: 0,
        },
        {
          name: 'Other income',
          uv: otherIncomeData.monthData.value,
          pv: otherIncomeData.prevMonthData.value,
        },
        {
          name: 'Other expenses',
          uv: otherExpensesData.monthData.value,
          pv: ebitdaData.monthData.value,
        },
        {
          name: 'Net profit',
          uv: netProfitData.monthData.value,
          pv: 0,
          amt: revenueData.monthData.value,
        },
      ],
    };
  };

  public getData(type: PlDashboardDataType) {
    const method = this.methodMap.get(type);
    if (!method) {
      console.warn(`No method available for type: ${type}`);
      return null;
    }
    const result = method();
    return result;
  }

  private processDataItemWithDate(
    item: DashboardDataItem | DashboardDataItemWithCategory
  ) {
    return DateTime.local(item.year, item.month, 1).toFormat('yyyy-MM');
  }

  private getDateFormMonthData(
    dataItems: DashboardDataItem[] | DashboardDataItemWithCategory[]
  ): DashboardDataItem[] | DashboardDataItemWithCategory[] {
    if (!dataItems || dataItems.length === 0) {
      return [];
    }

    if (this.startDate && this.endDate) {
      return dataItems?.filter((item) => {
        const itemDate = new Date(item.year, item.month - 1);

        const isSameMonthAsStart =
          itemDate.getFullYear() === this.startDate?.getFullYear() &&
          itemDate.getMonth() === this.startDate.getMonth();
        const isSameMonthAsEnd =
          itemDate.getFullYear() === this.endDate?.getFullYear() &&
          itemDate.getMonth() === this.endDate.getMonth();

        return (
          isSameMonthAsStart ||
          isSameMonthAsEnd ||
          (this.startDate &&
            this.endDate &&
            itemDate >= this.startDate &&
            itemDate <= this.endDate)
        );
      });
    }

    if (this.date) {
      return dataItems.filter((it) => {
        return `${it.year}-${it.month}` === this.date;
      });
    }

    return dataItems;
  }
}
