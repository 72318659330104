import {
  HistogramDataset,
  SnapshotDashboardDataType,
  SummaryDataset,
} from '../interfaces/dashboard-card.type';
import { DateTime } from 'luxon';
import {
  DashboardValueBuilderv2,
  formatDate,
  reverseSign,
} from '../../../../utils/dashboard/formatters';
import {
  DashboardDataItem,
  DashboardDataItemWithCategory,
} from '../interfaces/dashboard-data-item.interface';
import { SnapshotDashboardData } from '../interfaces/snapshot-dashboard-data.interface';
import {
  calculatePercentageChange,
  calculateRatio,
  findMetricByDate,
  getLastClosedMonth,
  getLongestDateRange,
} from '../utils';

export class SnapshotDashboardDataManager {
  private date: string | null = null;
  private startDate: Date | null = null;
  private endDate: Date | null = null;
  private methodMap: Map<SnapshotDashboardDataType, any>;

  constructor(private readonly dashboardData: SnapshotDashboardData) {
    this.methodMap = new Map<SnapshotDashboardDataType, any>([
      [SnapshotDashboardDataType.INFLOW_OUTFLOW, this.getInflowOutflowData],
      [
        SnapshotDashboardDataType.CUSTOMERS_SUMMARY,
        this.getCustomersSummaryData,
      ],
      [SnapshotDashboardDataType.CAC_SUMMARY, this.getCACSummaryData],
      [SnapshotDashboardDataType.ARPU_SUMMARY, this.getARPUSummaryData],
      [SnapshotDashboardDataType.LTV_SUMMARY, this.getLTVSummaryData],
      [
        SnapshotDashboardDataType.CHURN_RATE_SUMMARY,
        this.getChurnRateSummaryData,
      ],
      [SnapshotDashboardDataType.LTV_CAC_SUMMARY, this.getLTVCACSummaryData],
      [SnapshotDashboardDataType.NET_BURN_RATE, this.getNetBurnRateData],
      [
        SnapshotDashboardDataType.EXPENSES_VS_BUDGET,
        this.getExpensesVsBudgetData,
      ],
      [SnapshotDashboardDataType.AVB_INFLOW, this.getActualVsBudgetInflowData],
      [
        SnapshotDashboardDataType.AVB_OUTFLOW,
        this.getActualVsBudgetOutflowData,
      ],
      [SnapshotDashboardDataType.REVENUE, this.getActualVsProjectedRevenueData],
      [SnapshotDashboardDataType.PL, this.getPlData],
      [SnapshotDashboardDataType.REVENUE_GROWTH, this.getRevenueGrowthData],
      [SnapshotDashboardDataType.COGS_OPEX, this.getCogsAndOpexData],
      [SnapshotDashboardDataType.OPEX_TABLE, this.getOpexTableData],
      [SnapshotDashboardDataType.COGS_TABLE, this.getCogsTableData],
      [SnapshotDashboardDataType.MARGIN, this.getMarginData],
      [
        SnapshotDashboardDataType.NET_INCOME_PROFITABILITY,
        this.getNetIncomeProfitabilityData,
      ],
      [SnapshotDashboardDataType.PL_STRUCTURE_TABLE, this.getPlStructureData],
      [SnapshotDashboardDataType.WATERFALL_1, this.test_waterfall],
      [SnapshotDashboardDataType.TEST_COMBINED_V1, this.test_combinedV1],
      [SnapshotDashboardDataType.TEST_COMBINED_V2, this.test_combinedV2],
    ]);
  }
  public test_waterfall() {
    return {
      data: [],
      keys: ['value'],
    };
  }

  public test_combinedV1 = () => {
    // return {
    //   data: [],
    //   keys: ['value']
    // }
    const { netProfitMarginMetrics, netProfitActualMetrics } =
      this.dashboardData;

    const filteredNetProfitActualMetrics = this.getDateFormMonthData(
      netProfitActualMetrics
    );
    const filteredNetProfitMarginMetrics = this.getDateFormMonthData(
      netProfitMarginMetrics
    );

    const marginData = filteredNetProfitActualMetrics.map((metric) => {
      const { year, month, value } = metric;

      return {
        date: DateTime.local(year, month, 1).toFormat('yyyy-MM'),
        'Net Profit Margin': value,
      };
    });

    const builtLineData = filteredNetProfitMarginMetrics.map((metric) => {
      const { year, month, value } = metric;

      return {
        x: DateTime.local(year, month, 1).toFormat('yyyy-MM'),
        y: value,
      };
    });

    return {
      data: marginData,
      keys: ['Net Profit Margin'],
      indexBy: 'date',
      extraLineData: {
        line1: builtLineData,
      },
    };
  };

  public test_combinedV2() {
    return {};
  }
  public setDate(date: string) {
    this.date = date;
  }

  public setDateRange(startDate: Date | null, endDate: Date | null) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  public getHeaderData(key: keyof SnapshotDashboardData) {
    const data = this.dashboardData[key];
    const { month: targetMonth, year: targetYear } = getLastClosedMonth(
      this.endDate
    );
    const monthData = this.getDateFormMonthData(data).find(
      (d) => d.month === targetMonth && d.year === targetYear
    );

    const prevMonthData = data.find(
      (item) => item.year === targetYear && item.month === targetMonth - 1
    );

    if (!monthData) {
      return {
        month: targetMonth,
        year: targetYear,
        monthData: {
          value: 0,
        },
        prevMonthData: {
          value: prevMonthData?.value || 0,
        },
      };
    }

    return {
      month: monthData.month || 0,
      year: monthData.year,
      monthData: {
        value: monthData.value || 0,
      },
      prevMonthData: {
        value: prevMonthData?.value || 0,
      },
    };
  }

  public getData(type: SnapshotDashboardDataType) {
    const method = this.methodMap.get(type);
    if (!method) {
      console.warn(`No method available for type: ${type}`);
      return null;
    }
    const result = method();
    return result;
  }

  public getCustomersSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(SnapshotDashboardDataType.CUSTOMERS_SUMMARY);
  };

  public getARPUSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(SnapshotDashboardDataType.ARPU_SUMMARY);
  };

  public getLTVSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(SnapshotDashboardDataType.LTV_SUMMARY);
  };

  public getCACSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(SnapshotDashboardDataType.CAC_SUMMARY);
  };

  public getChurnRateSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      SnapshotDashboardDataType.CHURN_RATE_SUMMARY,
      'percent'
    );
  };

  public getLTVCACSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(SnapshotDashboardDataType.LTV_CAC_SUMMARY);
  };

  public getInflowOutflowData = (): HistogramDataset => {
    const { cashInflowActualMetrics, cashOutflowActualMetrics } =
      this.dashboardData;

    const inflowData = this.getDateFormMonthData(cashInflowActualMetrics);

    const inflowVsOutflow = inflowData.map((inflowMetric) => {
      const { year, month, value: inflowValue } = inflowMetric;
      const outflowMetric = cashOutflowActualMetrics.find(
        (outflowMetric) =>
          outflowMetric.year === year && outflowMetric.month === month
      );

      return {
        date: `${year}-${month}`,
        Inflow: inflowValue,
        Outflow: reverseSign(outflowMetric?.value || 0),
      };
    });

    return {
      data: inflowVsOutflow,
      keys: ['Inflow', 'Outflow'],
      indexBy: 'date',
    };
  };

  public getNetBurnRateData = () => {
    return this.getHistogramSingleData(
      'netBurnRateMetrics',
      'Net Burn Rate',
      true
    );
  };

  public getExpensesVsBudgetData = () => {
    const { opexCategoriesMetrics } = this.dashboardData;

    const { year, month } = getLastClosedMonth(this.endDate);
    let lastMonthData = this.getDateFormMonthData(opexCategoriesMetrics).filter(
      (d) => d.year === year && d.month === month
    ) as DashboardDataItemWithCategory[];

    lastMonthData = lastMonthData.sort((a, b) => a.value - b.value);

    if (!lastMonthData?.length) {
      return {
        data: [],
        keys: ['value'],
        indexBy: 'category',
        date: formatDate(`${year}-${month}`),
      };
    }

    const data = lastMonthData
      .slice(0, 7)
      .reduce(
        (acc: { date: string; value: number; category: string }[], item) => {
          if (item.month === month && item.year === year) {
            acc.push({
              date: this.processDataItemWithDate(item),
              value: reverseSign(item.value),
              category: item.category,
            });
          }
          return acc;
        },
        []
      );

    return {
      data,
      keys: ['value'],
      indexBy: 'category',
      date: formatDate(`${year}-${month}`),
    };
  };

  public getActualVsBudgetInflowData = () => {
    return this.getActualVsBudgetPie(
      'cashInflowActualMetrics',
      'cashInflowBudgetedMetrics'
    );
  };

  public getActualVsBudgetOutflowData = () => {
    return this.getActualVsBudgetPie(
      'cashOutflowActualMetrics',
      'cashOutflowBudgetedMetrics'
    );
  };

  public getActualVsProjectedRevenueData = () => {
    const { revenueActualMetrics, revenueProjectedMetrics } =
      this.dashboardData;
    const filteredRevenueActualMetrics =
      this.getDateFormMonthData(revenueActualMetrics);

    const actualVsProjected = filteredRevenueActualMetrics.map(
      (actualMetric) => {
        return {
          date: this.processDataItemWithDate(actualMetric),
          Projected: findMetricByDate(
            revenueProjectedMetrics,
            actualMetric.year,
            actualMetric.month
          ),
          Actual: actualMetric.value,
        };
      }
    );

    const keys = ['Actual'];

    if (actualVsProjected[0].Projected) {
      keys.push('Projected');
    }

    return {
      data: actualVsProjected,
      keys: keys,
      indexBy: 'date',
    };
  };

  public getRevenueGrowthData = () => {
    const { revenueGrowthActualMetricsAsPercent, expensesGrowthMetrics } =
      this.dashboardData;

    const processMetrics = (metrics: DashboardDataItem[]) => {
      return this.getDateFormMonthData(metrics)
        .map(({ year, month, value }) => ({
          x: DateTime.local(year, month, 1).toFormat('yyyy-MM'),
          y: value,
        }))
        .sort(
          (a, b) =>
            DateTime.fromFormat(a.x, 'yyyy-MM').toMillis() -
            DateTime.fromFormat(b.x, 'yyyy-MM').toMillis()
        );
    };

    const synchronizeData = (lines: any[]) => {
      const allMonths = new Set(
        lines.flatMap((line) =>
          line.data.map((point: { x: string }) => point.x)
        )
      );

      return lines.map((line) => {
        const filledData = Array.from(allMonths)
          .sort()
          .map((month) => {
            const existingPoint = line.data.find(
              (point: { x: string }) => point.x === month
            );
            return existingPoint || { x: month, y: 0 };
          });

        return { ...line, data: filledData };
      });
    };

    const line1 = processMetrics(revenueGrowthActualMetricsAsPercent).map(
      (item) => ({
        ...item,
        y: parseFloat((item.y / 100).toFixed(2)),
      })
    );

    const line2 = processMetrics(expensesGrowthMetrics);

    const dataset = synchronizeData([
      { key: 'Revenue Growth', data: line1 },
      { key: 'Expenses Growth', data: line2 },
    ]);

    return {
      dataset,
    };
  };

  public getPlData = () => {
    const {
      netProfitActualMetrics,
      revenueActualMetrics,
      opexActualMetrics,
      costOfSalesActualMetrics,
    } = this.dashboardData;

    const filteredNetProfitMarginMetrics = this.getDateFormMonthData(
      netProfitActualMetrics
    );
    const filteredRevenueActualMetrics =
      this.getDateFormMonthData(revenueActualMetrics);
    const filteredOpexMetrics = this.getDateFormMonthData(opexActualMetrics);
    const filteredCostOfSalesMetrics = this.getDateFormMonthData(
      costOfSalesActualMetrics
    );

    const formattedPlByDate = [];

    const longestDateRange = getLongestDateRange([
      filteredNetProfitMarginMetrics,
      filteredRevenueActualMetrics,
      filteredOpexMetrics,
      filteredCostOfSalesMetrics,
    ]);

    let current = longestDateRange.start;
    while (current <= longestDateRange.end) {
      const year = current.year;
      const month = current.month;
      const netProfitMargin = findMetricByDate(
        filteredNetProfitMarginMetrics,
        year,
        month
      );
      const revenueByDate = findMetricByDate(
        filteredRevenueActualMetrics,
        year,
        month
      );
      const opexByDate = findMetricByDate(filteredOpexMetrics, year, month);
      const cogsByDate = findMetricByDate(
        filteredCostOfSalesMetrics,
        year,
        month
      );

      formattedPlByDate.push({
        date: `${year}-${month}`,
        Revenue: revenueByDate?.value || 0,
        'Net Profit': netProfitMargin?.value || 0,
        OPEX: reverseSign(opexByDate?.value) || 0,
        COGS: reverseSign(cogsByDate?.value) || 0,
      });
      current = current.plus({ months: 1 });
    }

    return {
      keys: ['Revenue', 'COGS', 'OPEX', 'Net Profit'],
      indexBy: 'date',
      data: formattedPlByDate,
    };
  };

  public getOpexTableData = () => {
    return this.prepareTableData('opexCategoriesMetrics');
  };

  public getCogsTableData = () => {
    return this.prepareTableData('cogsCategoriesMetrics');
  };

  public getMarginData = () => {
    const metrics = this.getHistogramSingleData(
      'grossProfitActualMetrics',
      'Gross Profit, $'
    );
    const { grossProfitMarginActualMetrics } = this.dashboardData;
    const filteredGrossProfitMarginalityMetrics = this.getDateFormMonthData(
      grossProfitMarginActualMetrics
    );

    const lineDataWithValues = metrics.data.map((item) => {
      return {
        ...item,
        'Gross Profit Margin, %':
          filteredGrossProfitMarginalityMetrics.find(
            (metric) => item.date === this.processDataItemWithDate(metric)
          )?.value || 0,
      };
    });

    return {
      data: lineDataWithValues,
      keys: ['Gross Profit, $', 'Gross Profit Margin, %'],
    };
  };

  public getNetIncomeProfitabilityData = () => {
    const { netProfitMarginMetrics, netProfitActualMetrics } =
      this.dashboardData;

    const filteredNetProfitActualMetrics = this.getDateFormMonthData(
      netProfitActualMetrics
    );
    const filteredNetProfitMarginMetrics = this.getDateFormMonthData(
      netProfitMarginMetrics
    );

    const marginData = filteredNetProfitActualMetrics.map((metric) => {
      const { year, month, value } = metric;

      return {
        date: DateTime.local(year, month, 1).toFormat('yyyy-MM'),
        'Net Profit, $': value,
      };
    });

    const marginDataWithLine = marginData.map((item) => {
      return {
        ...item,
        'Net Profit, $': item['Net Profit, $'],
        'Net Profit Margin, %':
          filteredNetProfitMarginMetrics.find(
            (metric) => item.date === this.processDataItemWithDate(metric)
          )?.value || 0,
      };
    });

    return {
      data: marginDataWithLine,
      keys: ['Net Profit, $', 'Net Profit Margin, %'],
    };
  };

  private prepareTableData(key: keyof SnapshotDashboardData) {
    const tableDataItems = this.dashboardData[
      key
    ] as DashboardDataItemWithCategory[];

    const monthTableData = this.getDateFormMonthData(
      tableDataItems
    ) as DashboardDataItemWithCategory[];
    const { year, month } = getLastClosedMonth(this.endDate);
    const lastMonthDataSet = monthTableData.filter(
      (item) => item.year === year && item.month === month
    );

    lastMonthDataSet.sort((a, b) => a.value - b.value);

    const totalCurrentMonth = lastMonthDataSet.reduce((acc, item) => {
      return acc + item.value;
    }, 0);

    const totalPreviousMonth = lastMonthDataSet.reduce((acc, item) => {
      const prevMonthData: DashboardDataItemWithCategory | undefined =
        monthTableData.find((prevItem) => {
          return (
            prevItem.category === item.category &&
            prevItem.year === item.year &&
            prevItem.month === (item.month === 1 ? 12 : item.month - 1)
          );
        });

      return acc + (prevMonthData?.value || 0);
    }, 0);

    const result = lastMonthDataSet.map((item) => {
      const prevMonthData: DashboardDataItemWithCategory | undefined =
        tableDataItems.find((prevItem) => {
          return (
            prevItem.category === item.category &&
            prevItem.year === item.year &&
            prevItem.month === (item.month === 1 ? 12 : item.month - 1)
          );
        });

      const formattedCurrent = new DashboardValueBuilderv2(item.value)
        .reverseSignIfNegative()
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue();
      const formattedPrevious = new DashboardValueBuilderv2(
        prevMonthData?.value || 0
      )
        .reverseSignIfNegative()
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue();

      return {
        category: item.category,
        current: {
          type: 'ACTUAL',
          value: formattedCurrent,
        },
        previous: {
          type: 'BUDGET',
          value: formattedPrevious,
        },
        change: calculatePercentageChange(item.value, prevMonthData?.value),
      };
    });

    return {
      rows: result,
      totalCurrentMonth: new DashboardValueBuilderv2(totalCurrentMonth)
        .reverseSignIfNegative()
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue(),
      totalPreviousMonth: new DashboardValueBuilderv2(totalPreviousMonth)
        .reverseSignIfNegative()
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue(),
      currentHeader: formatDate(`${year}-${month}`),
      previousHeader: formatDate(`${year}-${month === 1 ? 12 : month - 1}`),
    };
  }

  public getCogsAndOpexData = () => {
    const { revenueByCategoriesMetrics } = this.dashboardData;

    const filteredRevenueByCategoriesMetrics = this.getDateFormMonthData(
      revenueByCategoriesMetrics
    ) as DashboardDataItemWithCategory[];

    const dateMap = new Map();
    const categories = filteredRevenueByCategoriesMetrics.map(
      (item) => item.category
    );
    const uniqueCategories = Array.from(new Set(categories));

    for (const metrics of filteredRevenueByCategoriesMetrics) {
      const { year, month, value, category } = metrics;
      const date = `${year}-${month}`;

      if (!dateMap.has(date)) {
        dateMap.set(date, { date });
      }

      const currentEntry = dateMap.get(date);
      currentEntry[category] = value;

      dateMap.set(date, currentEntry);
    }

    const data = Array.from(dateMap.values());

    return {
      data: data,
      keys: uniqueCategories,
      indexBy: 'date',
    };
  };

  private getPlStructureData = () => {
    const {
      revenueActualMetrics,
      costOfSalesActualMetrics,
      opexActualMetrics,
      netProfitActualMetrics,
    } = this.dashboardData;

    const { year, month } = getLastClosedMonth(this.endDate);

    const revenueMonthData = findMetricByDate(
      this.getDateFormMonthData(revenueActualMetrics),
      year,
      month
    );
    const cogsMonthData = findMetricByDate(
      this.getDateFormMonthData(costOfSalesActualMetrics),
      year,
      month
    );
    const opexMonthData = findMetricByDate(
      this.getDateFormMonthData(opexActualMetrics),
      year,
      month
    );
    const marginMonthData = findMetricByDate(
      this.getDateFormMonthData(netProfitActualMetrics),
      year,
      month
    );

    const date = formatDate(`${year}-${month}`);

    const keys = ['Profit & Loss Structure', date, '% from revenue'];

    const revenueValue = new DashboardValueBuilderv2(
      revenueMonthData?.value || 0
    )
      .formatValue(1)
      .shouldIncludeLetter(true)
      .getValue();
    const cogsValue = new DashboardValueBuilderv2(cogsMonthData?.value || 0)
      .formatValue(1)
      .shouldIncludeLetter(true)
      .getValue();
    const opexValue = new DashboardValueBuilderv2(opexMonthData?.value || 0)
      .formatValue(1)
      .shouldIncludeLetter(true)
      .getValue();
    const marginValue = new DashboardValueBuilderv2(marginMonthData?.value || 0)
      .formatValue(1)
      .shouldIncludeLetter(true)
      .getValue();

    const revenuePercentValue = revenueMonthData?.value
      ? calculateRatio(revenueMonthData.value, revenueMonthData.value) * 100
      : 0;
    const cogsPercentValue = revenueMonthData?.value
      ? calculateRatio(cogsMonthData?.value, revenueMonthData.value) * 100
      : 0;
    const opexPercentValue = revenueMonthData?.value
      ? calculateRatio(opexMonthData?.value, revenueMonthData.value) * 100
      : 0;
    const marginPercentValue = revenueMonthData?.value
      ? calculateRatio(marginMonthData?.value, revenueMonthData.value) * 100
      : 0;

    const prepareData = [
      {
        [keys[0]]: 'Revenue',
        [keys[1]]: revenueValue,
        [keys[2]]: revenuePercentValue.toFixed(2) + '%',
      },
      {
        [keys[0]]: 'COGS',
        [keys[1]]: cogsValue,
        [keys[2]]: cogsPercentValue.toFixed(2) + '%',
      },
      {
        [keys[0]]: 'OPEX',
        [keys[1]]: opexValue,
        [keys[2]]: opexPercentValue.toFixed(2) + '%',
      },
      {
        [keys[0]]: 'Profit',
        [keys[1]]: marginValue,
        [keys[2]]: marginPercentValue.toFixed(2) + '%',
      },
    ];

    return {
      data: prepareData,
      columns: keys,
    };
  };

  private getActualVsBudgetPie(
    keysFromData1: keyof SnapshotDashboardData,
    keysFromData2: keyof SnapshotDashboardData
  ) {
    const data1 = this.dashboardData[keysFromData1];
    const data2 = this.dashboardData[keysFromData2];

    const { year, month } = getLastClosedMonth(this.endDate);
    const actualData = findMetricByDate(
      this.getDateFormMonthData(data1),
      year,
      month
    );
    const budgetedData = findMetricByDate(
      this.getDateFormMonthData(data2),
      year,
      month
    );

    return {
      name2: 'ACTUAL',
      name1: 'BUDGET',
      value2: actualData?.value || null,
      value1: budgetedData?.value || undefined,
    };
  }

  private getHistogramSingleData(
    keyFrom: keyof SnapshotDashboardData,
    keyTo: string,
    reverse = false
  ) {
    const data = this.dashboardData[keyFrom];
    const filteredData = this.getDateFormMonthData(data);

    const remappedData = filteredData.map((dataItem) => {
      return {
        date: this.processDataItemWithDate(dataItem),
        [keyTo]: reverse ? reverseSign(dataItem.value) : dataItem.value,
      };
    });

    return {
      data: remappedData,
      keys: [keyTo],
      indexBy: 'date',
    };
  }

  private processDataItemWithDate(
    item: DashboardDataItem | DashboardDataItemWithCategory
  ) {
    return DateTime.local(item.year, item.month, 1).toFormat('yyyy-MM');
  }

  private getSummaryData(
    type: SnapshotDashboardDataType,
    signType: 'percent' | 'value' = 'value'
  ): SummaryDataset | null {
    const map = new Map<SnapshotDashboardDataType, keyof SnapshotDashboardData>(
      [
        [SnapshotDashboardDataType.CUSTOMERS_SUMMARY, 'uniqueCustomersMetrics'],
        [SnapshotDashboardDataType.ARPU_SUMMARY, 'arpuMetrics'],
        [SnapshotDashboardDataType.CAC_SUMMARY, 'cacMetrics'],
        [
          SnapshotDashboardDataType.LTV_SUMMARY,
          'customersLifetimeValueMetrics',
        ],
        [
          SnapshotDashboardDataType.CHURN_RATE_SUMMARY,
          'customersChurnRateMetrics',
        ],
        [SnapshotDashboardDataType.LTV_CAC_SUMMARY, 'ltvCacMetrics'],
      ]
    );

    if (!map.has(type)) {
      console.warn(`No data available for type: ${type}`);
      return null;
    }

    const result = this.getHeaderData(
      map.get(type) as keyof SnapshotDashboardData
    );

    return {
      value: result.monthData.value ?? 0,
      prevMonthData: result.prevMonthData.value ?? 0,
      year: result?.year ?? 0,
      month: result?.month ?? 0,
      signType,
    };
  }

  private getDateFormMonthData(
    dataItems: DashboardDataItem[] | DashboardDataItemWithCategory[]
  ): DashboardDataItem[] | DashboardDataItemWithCategory[] {
    if (!dataItems || dataItems.length === 0) {
      return [];
    }

    if (this.startDate && this.endDate) {
      return dataItems?.filter((item) => {
        const itemDate = new Date(item.year, item.month - 1);

        const isSameMonthAsStart =
          itemDate.getFullYear() === this.startDate?.getFullYear() &&
          itemDate.getMonth() === this.startDate.getMonth();
        const isSameMonthAsEnd =
          itemDate.getFullYear() === this.endDate?.getFullYear() &&
          itemDate.getMonth() === this.endDate.getMonth();

        return (
          isSameMonthAsStart ||
          isSameMonthAsEnd ||
          (this.startDate &&
            this.endDate &&
            itemDate >= this.startDate &&
            itemDate <= this.endDate)
        );
      });
    }

    if (this.date) {
      return dataItems.filter((it) => {
        return `${it.year}-${it.month}` === this.date;
      });
    }

    return dataItems;
  }
}
