import { useState, useEffect, useRef } from 'react';
import { MappingMasterList } from '../../../tools/manager-api/types/mapping';

interface Option {
  id: number;
  name: string;
}

interface CustomSelectWithSearchProps {
  options: Option[];
  value: MappingMasterList | null;
  onChange: (value: MappingMasterList | null) => void;
  placeholder: string;
}

export const CustomSelectWithSearch: React.FC<CustomSelectWithSearchProps> = ({
  options,
  value,
  onChange,
  placeholder,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [displayedOptions, setDisplayedOptions] = useState<Option[]>([]);
  const [maxOptionsToShow, setMaxOptionsToShow] = useState<number>(20);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!searchTerm) {
      setDisplayedOptions(options.slice(0, maxOptionsToShow));
    }
  }, [options, maxOptionsToShow, searchTerm]);
  useEffect(() => {
    if (searchTerm) {
      const filtered = options
        .filter((option) =>
          option.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(0, maxOptionsToShow);
      setDisplayedOptions(filtered);
    }
  }, [searchTerm, options, maxOptionsToShow]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
        setSearchTerm('');
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight) {
      setMaxOptionsToShow((prev) => prev + 20);
    }
  };

  return (
    <div
      className={`custom-select-wrapper ${isDropdownOpen ? 'open' : ''}`}
      ref={dropdownRef}
    >
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm || value?.name || ''}
        onFocus={() => {
          setIsDropdownOpen(true);
          setMaxOptionsToShow(20);
        }}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      {isDropdownOpen && (
        <ul className="dropdown-options" onScroll={handleScroll}>
          <li
            key="choose-option"
            onClick={() => {
              onChange(null);
              setIsDropdownOpen(false);
              setSearchTerm('');
            }}
            className="dropdown-item"
          >
            Empty
          </li>
          {displayedOptions.map((option, idx) => (
            <li
              key={idx}
              onClick={() => {
                onChange(option as MappingMasterList);
                setIsDropdownOpen(false);
                setSearchTerm(option.name);
              }}
              className="dropdown-item"
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
