import React from 'react';

type HeatmapProps = {
  data: number[][];
  colorScale: string[];
  products: string[];
};

const getColor = (value: number): string => {
  // Normalize the value between 0 and 1
  const normalizedValue = value / 100;

  // Gradient from light purple to dark purple (can be adjusted)
  const startColor = 'rgba(220, 220, 255, 1)'; // Light purple
  const endColor = 'rgba(128, 0, 128, 1)'; // Dark purple

  // Linear interpolation for RGBA values
  const startRGB = startColor.match(/\d+/g)?.map(Number) ?? [];
  const endRGB = endColor.match(/\d+/g)?.map(Number) ?? [];
  const gradientColor = startRGB.map((start, i) => {
    const end = endRGB[i];
    return Math.round(start + normalizedValue * (end - start));
  });

  return `rgba(${gradientColor.join(',')})`;
};

const Heatmap: React.FC<HeatmapProps> = ({ data, colorScale, products }) => {
  if (!data || data?.length === 0) {
    return <p>No data provided for the reporting month</p>;
  }

  return (
    <div
      className="heatmap-card"
      style={{ fontSize: '0.8em', padding: '10px', overflowX: 'auto' }}
    >
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr>
            <th
              style={{
                padding: '4px',
                textAlign: 'center',
                border: '0 2px 2px 2px solid #d9d9d9',
              }}
            ></th>
            {data?.[0]?.map((_, colIndex) => (
              <th
                key={colIndex}
                style={{
                  padding: '4px',
                  textAlign: 'center',
                  border: '2px solid #d9d9d9',
                  borderTop: 'none',
                  fontWeight: 'normal',
                }}
              >
                mon {colIndex + 1}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td
                style={{
                  padding: '4px',
                  textAlign: 'center',
                  border: '2px solid #d9d9d9',
                  borderLeft: 'none',
                }}
              >
                {products[rowIndex] ||
                  `Product ${String.fromCharCode(65 + rowIndex)}`}
              </td>
              {row.map((value, colIndex) => (
                <td
                  key={colIndex}
                  style={{
                    backgroundColor: getColor(value),
                    color: value > 50 ? '#fff' : '#000',
                    padding: '4px',
                    textAlign: 'center',
                    width: '30px',
                    height: '30px',
                    border: '2px solid #d9d9d9',
                    backgroundImage:
                      value > 50
                        ? `linear-gradient(to top, ${getColor(
                            value
                          )} 0%, ${getColor(value - 30)} 100%)`
                        : 'none',
                  }}
                >
                  {value}%
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Heatmap;
