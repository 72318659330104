import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  MappingMasterList,
  SourceType,
} from '../../../tools/manager-api/types/mapping';
import { ManagerHttpMappingService } from '../../../tools/manager-api/manager-http-mapping';
import { Edit3, Trash2, ArrowUp, ArrowDown } from 'lucide-react';
import AddEmployeeModal from '../modals/add-employee/add-employee.modal';
import UpdateEmployeeModal from '../modals/update-employee/update-employee.modal';
import arrowImg from '../../../../../public/images/arrow.png';
import { ToasterContext } from '../../../context/toaster.context';
import ChooseBaseModal from '../modals/choose-base/choose-base.modal';
import { SearchBar } from '../components/search-bar.component';
import { Pagination } from '../components/pagination.component';
import { Loader } from '../components/loader.component';
import { CustomSelectWithSearch } from '../components/custom-select-search.component';
import { UpdatedButtonComponent } from '../../../shared-components/button/updated.button.component';
import { useMapping } from '../mapping.hook';
const PAGE_LIMIT = 50;
const SOURCE_TYPE: SourceType = 'EMPLOYEE';

export const Employees = () => {
  const { companyId } = useParams();
  const {
    fuelRecommendations,
    integrations,
    integrationMappings,
    allMasterListData,
    totalMasterCount,
    totalMappingCount,
    loading,
    chooseBaseModalActive,
    fetchIntegrationsAndMasterLists,
    fetchMappings,
    fetchAllMasterListData,
    setLoading,
    setChooseBaseModalActive,
    setIntegrationMappings,
  } = useMapping();
  const [activeIntegration, setActiveIntegration] = useState<string | null>(
    null
  );
  const [addEmployeeModalActive, setAddEmployeeModalActive] =
    useState<boolean>(false);
  const [updateEmployeeModalActive, setUpdateEmployeeModalActive] =
    useState<boolean>(false);
  const [employeeToUpdate, setEmployeeToUpdate] = useState<{
    id: number;
    employeeName: string;
    deliveryTeam: boolean;
    startDate: string;
    endDate: string;
  } | null>(null);

  const [updatedMappings, setUpdatedMappings] = useState<
    { id: number; from: string; to: number | undefined }[]
  >([]);
  const [masterSearch, setMasterSearch] = useState<string>('');
  const [mappingSearch, setMappingSearch] = useState<string>('');
  const [masterPage, setMasterPage] = useState(1);
  const [mappingPage, setMappingPage] = useState(1);
  const [masterSorting, setMasterSorting] = useState<{
    field: string;
    order: 'asc' | 'desc';
  }>({
    field: 'name',
    order: 'asc',
  });
  const [mappingSorting, setMappingSorting] = useState<{
    field: string;
    order: 'asc' | 'desc';
  }>({
    field: 'name',
    order: 'asc',
  });
  const ctx = useContext(ToasterContext);

  const fetchMasterLists = (searchTerm: string) => {
    fetchIntegrationsAndMasterLists(SOURCE_TYPE, {
      page: masterPage,
      limit: PAGE_LIMIT,
      search: searchTerm,
      sorting: [{ key: masterSorting.field, order: masterSorting.order }],
    });
  };

  const fetchMappingData = (searchTerm: string) => {
    fetchMappings(SOURCE_TYPE, Number(activeIntegration), {
      page: mappingPage,
      limit: PAGE_LIMIT,
      search: searchTerm,
      sorting: [{ key: mappingSorting.field, order: mappingSorting.order }],
    });
  };

  useEffect(() => {
    fetchMasterLists(masterSearch);
  }, [companyId, masterPage, masterSorting, mappingSorting]);

  useEffect(() => {
    if (!activeIntegration) return;
    fetchMappingData(mappingSearch);
  }, [companyId, activeIntegration, mappingPage, mappingSorting]);

  useEffect(() => {
    fetchAllMasterListData(SOURCE_TYPE);
  }, [companyId, fuelRecommendations]);

  const handleAddEmployee = async (
    employeeName: string,
    deliveryTeam?: boolean,
    startDate?: string,
    endDate?: string
  ) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.addMasterList(Number(companyId), {
        name: employeeName,
        sourceType: SOURCE_TYPE,
        settings: { deliveryTeam, startDate, endDate },
      });
      setAddEmployeeModalActive(false);
      fetchMasterLists(masterSearch);
    } catch {
      ctx.updateToaster({
        content: 'Failed to add employee to master list',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMasterEmployee = async (employeeId: number) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.deleteMasterList(
        Number(companyId),
        employeeId,
        SOURCE_TYPE
      );
      fetchMasterLists(masterSearch);
    } catch {
      ctx.updateToaster({
        content: 'Failed to delete employee name from master list',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenUpdateModal = (employee: {
    id: number;
    employeeName: string;
    deliveryTeam: boolean;
    startDate: string;
    endDate: string;
  }) => {
    setEmployeeToUpdate(employee);
    setUpdateEmployeeModalActive(true);
  };

  const handleUpdateMasterEmployee = async (
    employeeId: number,
    employeeName: string,
    deliveryTeam?: boolean,
    startDate?: string,
    endDate?: string
  ) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.updateMasterList(
        Number(companyId),
        employeeId,
        {
          name: employeeName,
          sourceType: SOURCE_TYPE,
          settings: { deliveryTeam, startDate, endDate },
        }
      );
      setUpdateEmployeeModalActive(false);
      fetchMasterLists(masterSearch);
    } catch {
      ctx.updateToaster({
        content: 'Failed to update employee name',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMappingChange = (
    mappingId: number,
    value: MappingMasterList | null
  ) => {
    setIntegrationMappings((prev) => {
      if (!prev) {
        return null;
      }

      const newMappings = prev.map((mapping) =>
        mapping.id === mappingId ? { ...mapping, masterList: value } : mapping
      );
      return newMappings;
    });

    setUpdatedMappings((prev) => {
      const existing = prev.find((mapping) => mapping.id === mappingId);

      if (existing) {
        const updated = prev.map((mapping) =>
          mapping.id === mappingId ? { ...mapping, to: value?.id } : mapping
        );
        return updated;
      }

      const originalMapping = integrationMappings?.find(
        (mapping) => mapping.id === mappingId
      );

      if (!originalMapping) {
        return prev;
      }

      const newMapping = {
        id: mappingId,
        from: originalMapping.name || '',
        to: value?.id,
      };

      return [...prev, newMapping];
    });
  };

  const handleRemap = async (
    updatedMappings: { id: number; from: string; to: number | undefined }[]
  ) => {
    if (!updatedMappings?.length) {
      ctx.updateToaster({
        content: 'No changes to update',
        isError: true,
      });
      return;
    }
    setLoading(true);
    try {
      await ManagerHttpMappingService.remap(Number(companyId), {
        integrationId: Number(activeIntegration),
        sourceType: SOURCE_TYPE,
        updatedMappings,
      });
      setUpdatedMappings([]);
      fetchMappingData(mappingSearch);
      ctx.updateToaster({
        content:
          'The remapping process has begun and may take a few minutes to complete.',
        isError: false,
      });
    } catch {
      ctx.updateToaster({ content: 'Failed to remap', isError: true });
    } finally {
      setLoading(false);
    }
  };

  const handleChooseBase = async (
    integrationId: string,
    sourceType: SourceType
  ) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.addMasterLists(Number(companyId), {
        integrationId,
        sourceType,
      });
      fetchMasterLists(masterSearch);
    } catch {
      ctx.updateToaster({
        content: 'Failed to add master list item',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMasterSearch = () => {
    fetchMasterLists(masterSearch);
  };

  const handleMappingSearch = () => {
    fetchMappingData(mappingSearch);
  };

  const toggleMasterSorting = () => {
    setMasterSorting((prev) => ({
      field: 'name',
      order: prev.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const toggleMappingSorting = () => {
    setMappingSorting((prev) => ({
      field: 'name',
      order: prev.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const renderTableHeaders = () => {
    if (activeIntegration === null) {
      return (
        <tr>
          <th
            className="master-table-header"
            onClick={toggleMasterSorting}
            style={{ cursor: 'pointer' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>EMPLOYEE NAME</span>
              {masterSorting.order === 'asc' ? (
                <ArrowUp size={20} style={{ marginLeft: '8px' }} />
              ) : (
                <ArrowDown size={20} style={{ marginLeft: '8px' }} />
              )}
            </div>
          </th>
          <th className="master-table-header">DELIVERY TEAM</th>
          <th className="master-table-header">START DATE</th>
          <th className="master-table-header">END DATE</th>
          <th
            onClick={() => setAddEmployeeModalActive(true)}
            style={{ cursor: 'pointer', textAlign: 'end' }}
            className="master-table-header"
          >
            <span
              style={{
                textDecoration: 'underline',
                color: 'black',
                fontWeight: 'normal',
                fontSize: '1rem',
                margin: '0 0.5rem',
              }}
            >
              + EMPLOYEE
            </span>
          </th>
        </tr>
      );
    }

    return (
      <tr>
        <th
          className="mapping-table-header"
          onClick={toggleMappingSorting}
          style={{ cursor: 'pointer' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>EMPLOYEE NAME</span>
            {mappingSorting.order === 'asc' ? (
              <ArrowUp size={20} style={{ marginLeft: '8px' }} />
            ) : (
              <ArrowDown size={20} style={{ marginLeft: '8px' }} />
            )}
          </div>
        </th>
        <th></th>
        <th>EMPLOYEE IN FUEL SYSTEM</th>
        <th style={{ textAlign: 'end' }}>
          {updatedMappings?.length > 0 && (
            <UpdatedButtonComponent
              onClick={() => {
                handleRemap(updatedMappings);
              }}
              mini={false}
              type="default"
            >
              UPDATE MAPPING
            </UpdatedButtonComponent>
          )}
        </th>
      </tr>
    );
  };

  const renderTableRows = () => {
    if (activeIntegration === null) {
      return (
        <>
          {fuelRecommendations?.map((employee, index) => (
            <tr key={index}>
              <td className="master-table-row">{employee.name}</td>
              <td className="master-table-row">
                {employee?.settings?.deliveryTeam ? 'YES' : 'NO'}
              </td>
              <td className="master-table-row">
                {employee?.settings?.startDate || ''}
              </td>
              <td className="master-table-row">
                {employee?.settings?.endDate || ''}
              </td>
              <td className="actions master-table-row">
                <Edit3
                  className="edit-icon"
                  onClick={() =>
                    handleOpenUpdateModal({
                      id: employee.id,
                      employeeName: employee.name,
                      deliveryTeam: employee?.settings?.deliveryTeam || false,
                      startDate: employee?.settings?.startDate || '',
                      endDate: employee?.settings?.endDate || '',
                    })
                  }
                />
                <Trash2
                  className="mapping-delete-icon"
                  onClick={() => handleDeleteMasterEmployee(employee.id)}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: 'end' }}>
              <Pagination
                page={masterPage}
                totalCount={totalMasterCount}
                onPageChange={setMasterPage}
              />
            </td>
          </tr>
        </>
      );
    }

    return (
      <>
        {integrationMappings?.map((mapping, index) => (
          <tr key={index}>
            <td className="mapping-account">{mapping.name}</td>
            <td className="mapping-arrow">
              <img
                src={arrowImg}
                alt="arrow"
                style={{ width: '24px', height: '20px' }}
              />
            </td>
            <td className="mapping-fuel-account">
              <CustomSelectWithSearch
                options={allMasterListData}
                value={mapping.masterList || null}
                onChange={(selectedValue: MappingMasterList | null) =>
                  handleMappingChange(mapping.id, selectedValue)
                }
                placeholder="Choose employee"
              />
            </td>
          </tr>
        ))}
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ textAlign: 'end' }}>
            <Pagination
              page={mappingPage}
              totalCount={totalMappingCount}
              onPageChange={setMappingPage}
            />
          </td>
        </tr>
      </>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="mapping-employees">
      <div className="mapping-sub-menu">
        <div
          className={`mapping-sub-menu-item ${
            activeIntegration === null ? 'active' : ''
          }`}
          onClick={() => {
            setActiveIntegration(null);
            if (mappingSearch) {
              setMappingSearch('');
              setMappingPage(1);
              fetchMappingData('');
            }
          }}
        >
          master list
        </div>
        {integrations?.map((integration, index) => (
          <div
            key={index}
            className={`mapping-sub-menu-item ${
              activeIntegration === integration.integrationId ? 'active' : ''
            }`}
            onClick={() => {
              setActiveIntegration(integration.integrationId);
              if (masterSearch) {
                setMasterSearch('');
                setMasterPage(1);
                fetchMasterLists('');
              }
            }}
          >
            {`${integration.integrationName} ${integration.integrationId.slice(
              -4
            )}`}
          </div>
        ))}
      </div>
      <div className="mapping-search-action">
        {activeIntegration === null && (
          <SearchBar
            searchValue={masterSearch}
            setSearchValue={setMasterSearch}
            placeholder="Type employee name"
            onSearch={handleMasterSearch}
          />
        )}
        {activeIntegration !== null && (
          <SearchBar
            searchValue={mappingSearch}
            setSearchValue={setMappingSearch}
            placeholder="Type employee name"
            onSearch={handleMappingSearch}
          />
        )}
      </div>
      <table
        className={
          activeIntegration === null ? 'master-table' : 'mapping-table'
        }
      >
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTableRows()}</tbody>
      </table>

      {addEmployeeModalActive && (
        <AddEmployeeModal
          onAdd={handleAddEmployee}
          onClose={() => setAddEmployeeModalActive(false)}
        />
      )}
      {updateEmployeeModalActive && (
        <UpdateEmployeeModal
          employee={employeeToUpdate}
          onSave={handleUpdateMasterEmployee}
          onClose={() => setUpdateEmployeeModalActive(false)}
        />
      )}
      {chooseBaseModalActive && (
        <ChooseBaseModal
          onClose={() => setChooseBaseModalActive(false)}
          onConfirm={handleChooseBase}
          categories={integrations || []}
          sourceType="EMPLOYEE"
        />
      )}
    </div>
  );
};
