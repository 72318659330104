import './financial-reports.style.css';
import documentImage from '../../../../public/images/img.png';
import { useNewDocuments } from '../bootstrap/components/documents/hooks/new-documents.hook';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useCompanyDocuments } from '../../managers-pages/manager-company/company-documents/hooks/company-documents.hook';
import { useParams } from 'react-router-dom';
import { RoleContext } from '../../context/role.context';
import { CompanyDocument } from '../../tools/api-services/types/document';
import { AddReportModal } from '../../managers-pages/manager-company/company-documents/modals/add-document.modal';
import { DiagramChartsHolderWithType } from '../../components/shared/charts/manager-client.charts.components';
import { DashboardMenuItem } from '../../components/shared/menu/multi-menu.component';
import { X } from 'lucide-react';
import { DeleteReportModal } from '../../managers-pages/manager-company/company-documents/modals/delete-document.modal';

const CardPlaceholder = () => {
  return (
    <div className="reports-card-placeholder fade-out">
      <div className="report-placeholder-icon"></div>
      <div className="report-placeholder-text"></div>
    </div>
  );
};

const CardPlaceholderContainer = () => {
  return (
    <>
      <CardPlaceholder />
      <CardPlaceholder />
      <CardPlaceholder />
    </>
  );
};

const FinancialReportDocument = ({
  id,
  link,
  name,
  fetchDocuments,
}: {
  id: number;
  link: string;
  name: string;
  fetchDocuments: () => void;
}) => {
  const onCardClick = () => window.open(link, '_blank');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { companyId } = useParams();

  return (
    <>
      {isModalOpen && (
        <>
          <div
            className="modal-overlay"
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
          <DeleteReportModal
            documentId={id}
            onClose={() => setIsModalOpen(false)}
            fetchDocuments={fetchDocuments}
            companyId={Number(companyId)}
          />
        </>
      )}
      <div onClick={onCardClick} className="financial-reports-card">
        <X
          onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(true);
          }}
          className="delete-icon"
          style={{ width: '20px', height: '20px' }}
          strokeWidth={'2'}
        />
        <img src={documentImage} alt="Document" />
        <div>{name}</div>
      </div>
    </>
  );
};

const mapDocumentsToDisplay = (documents: any, fetchDocuments: () => void) => {
  return documents.map((document: any, i: number) => {
    return (
      <FinancialReportDocument
        key={i}
        {...document}
        fetchDocuments={fetchDocuments}
      />
    );
  });
};

const AddNewReport = ({ onClick }: any) => {
  return (
    <button onClick={onClick} className={'financial-reports-add-card'}>
      <p>+</p>
      <p>Add report</p>
    </button>
  );
};

const ManagerFinancialReportsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { role } = useContext(RoleContext);
  const params = useParams();
  const { companyId } = params;
  const { isLoading, documents, fetchDocuments } = useCompanyDocuments();

  useEffect(() => {
    if (companyId) {
      fetchDocuments(Number(companyId));
    }
  }, [companyId, fetchDocuments]);

  const handleFetchDocuments = useCallback(() => {
    fetchDocuments(Number(companyId));
  }, [companyId, fetchDocuments]);

  if (!role || role !== 'MANAGER') return;

  if (!companyId) {
    return <div>Company not found</div>;
  }

  return (
    <>
      {isModalOpen && (
        <>
          <div
            className="modal-overlay"
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
          <AddReportModal
            companyId={Number(companyId)}
            fetchDocuments={handleFetchDocuments}
            onClose={() => {
              setIsModalOpen(false);
            }}
          />
        </>
      )}
      <div className={'financial-reports-container'}>
        <AddNewReport onClick={() => setIsModalOpen(true)} />
        {isLoading && <CardPlaceholderContainer />}
        {!isLoading && mapDocumentsToDisplay(documents, handleFetchDocuments)}
      </div>
    </>
  );
};

export const ClientFinancialReportsPage = () => {
  const { documents, isLoading, fetchDocuments } = useNewDocuments();
  const handleFetchDocuments = useCallback(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return (
    <FinancialReportsPage
      isLoading={isLoading}
      documents={documents}
      fetchDocuments={handleFetchDocuments}
    />
  );
};

export const FinancialReportsPage = ({
  isLoading,
  documents,
  fetchDocuments,
}: {
  isLoading: boolean;
  documents: CompanyDocument[];
  fetchDocuments: () => void;
}) => {
  return (
    <div className={'financial-reports-container'}>
      {isLoading && <CardPlaceholderContainer />}
      {!isLoading && mapDocumentsToDisplay(documents, fetchDocuments)}
    </div>
  );
};

export const FinancialDocumentsRoleWrapper = () => {
  const { role } = useContext(RoleContext);

  if (role === 'CLIENT') {
    return <ClientFinancialReportsPage />;
  }

  if (role === 'MANAGER') {
    return <ManagerFinancialReportsPage />;
  }

  return null;
};

export const UpdatedFinancialReportPage = () => {
  const [selectedItem, setSelectedItem] = useState<
    | 'MANAGERIAL'
    | 'PROFIT_LOSS'
    | 'CASH_FLOW'
    | 'BALANCE_SHEET'
    | 'LINKS_TO_REPORTS'
  >('MANAGERIAL');

  const handleMenuClick = (
    page:
      | 'MANAGERIAL'
      | 'PROFIT_LOSS'
      | 'CASH_FLOW'
      | 'BALANCE_SHEET'
      | 'LINKS_TO_REPORTS'
  ) => {
    setSelectedItem(page);
  };

  return (
    <>
      <div className="dashboard-menu">
        <div className="menu">
          <div>
            <DashboardMenuItem
              isActive={selectedItem === 'MANAGERIAL'}
              onClick={() => handleMenuClick('MANAGERIAL')}
              name="MANAGERIAL"
            />
            <DashboardMenuItem
              isActive={selectedItem === 'PROFIT_LOSS'}
              onClick={() => handleMenuClick('PROFIT_LOSS')}
              name="PROFIT & LOSS"
            />
            <DashboardMenuItem
              isActive={selectedItem === 'CASH_FLOW'}
              onClick={() => handleMenuClick('CASH_FLOW')}
              name="CASH FLOW"
            />
            <DashboardMenuItem
              isActive={selectedItem === 'BALANCE_SHEET'}
              onClick={() => handleMenuClick('BALANCE_SHEET')}
              name="BALANCE SHEET"
            />
            <DashboardMenuItem
              isActive={selectedItem === 'LINKS_TO_REPORTS'}
              onClick={() => handleMenuClick('LINKS_TO_REPORTS')}
              name="LINKS TO REPORTS"
            />
            <div className="menu-spacer"></div>
          </div>
        </div>
        <div className="menu-underline"></div>
      </div>
      {
        {
          MANAGERIAL: (
            <DiagramChartsHolderWithType chartType={'MANAGERIAL_REPORTS'} />
          ),
          PROFIT_LOSS: (
            <DiagramChartsHolderWithType chartType={'PROFIT_AND_LOSS'} />
          ),
          CASH_FLOW: <DiagramChartsHolderWithType chartType={'CASH_FLOW'} />,
          BALANCE_SHEET: (
            <DiagramChartsHolderWithType chartType={'BALANCE_SHEET'} />
          ),
          LINKS_TO_REPORTS: <FinancialDocumentsRoleWrapper />,
        }[selectedItem]
      }
    </>
  );
};
