import { useContext, useEffect, useState } from 'react';
import { ConcreteCardFactory } from '../card.factory';
import { DashboardCard } from '../dashboard-card';
import { RevenueAnalysisDashboardDataManager } from '../data-managers/revenue-analysis-dashboard-data.manager';
import { ApiInstance } from '../../../../tools/api';
import { RoleContext } from '../../../../context/role.context';
import { useParams } from 'react-router-dom';
import { useDashboardStructure } from '../dashboard-structure.hook';
import { RevenueAnalysisDashboardData } from '../interfaces/revenue-analysis-data.interface';

const fetchRevenueAnalysisDashboardData = async (
  role: any,
  companyId: string | number | undefined
): Promise<RevenueAnalysisDashboardData> => {
  if (role.userType === 'MANAGER' && companyId) {
    const result = await new ApiInstance().instance.get(
      `/manager/companies/${companyId}/dashboard/metrics?categoryType=RevenueAnalysis`
    );
    return result.data;
  }
  const result = await new ApiInstance().instance.get(
    '/api/dashboard/metrics?categoryType=RevenueAnalysis'
  );
  return result.data;
};

const fetchDataForCard = async (
  cardSettings: any,
  dashboardData: RevenueAnalysisDashboardData,
  dateRange: {
    startDate: Date;
    endDate: Date;
  } | null
) => {
  const dashboardDataManager = new RevenueAnalysisDashboardDataManager(
    dashboardData
  );
  dashboardDataManager.setDateRange(
    dateRange?.startDate || null,
    dateRange?.endDate || null
  );

  const { identifier, name } = cardSettings;

  try {
    const dataResult = dashboardDataManager.getData(identifier);
    return {
      ...cardSettings,
      dataset: {
        name,
        dataset: dataResult,
      },
    };
  } catch (error) {
    return { ...cardSettings, name, dataset: {} };
  }
};

export const dashboardHeaderData = (
  dashboardData: RevenueAnalysisDashboardData,
  dateRange: {
    startDate: Date;
    endDate: Date;
  } | null
) => {
  const dataManager = new RevenueAnalysisDashboardDataManager(dashboardData);
  dataManager.setDateRange(
    dateRange?.startDate || null,
    dateRange?.endDate || null
  );
  return {
    revenue: dataManager.getHeaderData('revenueActualMetrics'),
    arr: dataManager.getHeaderData('revenueByCustomersMetrics'),
    users: dataManager.getHeaderData('activeCustomersMetrics'),
    churnRateInRevenue: dataManager.getHeaderData('churnRateInRevenueMetrics'),
    arpu: dataManager.getHeaderData('arpuMetrics'),
    ltvCac: dataManager.getHeaderData('ltvCacMetrics'),
  };
};

export const useRevenueAnalysisDashboard = (
  dateRange: { startDate: Date; endDate: Date } | null
) => {
  const role = useContext(RoleContext);
  const { companyId } = useParams();
  const { getDashboardStructure } = useDashboardStructure('REVENUE_ANALYSIS');
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState<{
    revenueCards: DashboardCard<unknown, unknown>[];
    userCards: DashboardCard<unknown, unknown>[];
    headerMetrics: any | null;
    churnRetentionCards: DashboardCard<unknown, unknown>[];
    ltvCards: DashboardCard<unknown, unknown>[];
  }>({
    revenueCards: [],
    userCards: [],
    headerMetrics: null,
    churnRetentionCards: [],
    ltvCards: [],
  });

  const getCardSettingsAndBuild = async () => {
    setLoading(true);
    try {
      const dashboardData = await fetchRevenueAnalysisDashboardData(
        role,
        companyId
      );
      const revenueCards = [];
      const userCards = [];
      const churnRetentionCards = [];
      const ltvCards = [];
      const headerMetrics = dashboardHeaderData(dashboardData, dateRange);

      const factory = new ConcreteCardFactory();
      const cardSettings = await getDashboardStructure();
      if (!cardSettings) {
        return;
      }
      for (const card of cardSettings) {
        const cardWithData = await fetchDataForCard(
          card,
          dashboardData,
          dateRange
        );

        const {
          type,
          identifier,
          layout,
          dataset,
          dashboardType,
          options,
          name,
          type: cardType,
        } = cardWithData;

        const newCard = factory.createCard(
          type,
          identifier,
          layout,
          dataset,
          options
        );

        newCard.setProperties({
          name,
          type: cardType,
          dashboardType,
        });

        if (newCard.component === null) {
          continue;
        }

        if (dashboardType === 'REVENUE') {
          revenueCards.push(newCard);
        } else if (dashboardType === 'USERS') {
          userCards.push(newCard);
        } else if (dashboardType === 'CHURN & RETENTION') {
          churnRetentionCards.push(newCard);
        } else {
          ltvCards.push(newCard);
        }
      }

      setCards({
        revenueCards,
        userCards,
        headerMetrics,
        churnRetentionCards,
        ltvCards,
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCardSettingsAndBuild();
    }

    return () => {
      isMounted = false;
    };
  }, [dateRange]);

  return {
    cards,
    loading,
  };
};
