export const snapshotDashboardSettings = [
  {
    type: 'HistogramCard',
    name: 'INFLOW VS OUTFLOW, $',
    identifier: 'INFLOW_OUTFLOW',
    layout: {
      width: 4,
      height: 6,
      xPosition: 0,
      yPosition: 0,
    },
    dashboardType: 'CASHFLOW',
    options: {
      colors: ['#864DFE', '#FF9500'],
    },
  },
  {
    type: 'SummaryCard',
    name: 'CUSTOMERS, #',
    identifier: 'CUSTOMERS_SUMMARY',
    layout: {
      xPosition: 4,
      yPosition: 0,
      width: 1,
      height: 2,
    },
    dashboardType: 'CASHFLOW',
  },
  {
    type: 'SummaryCard',
    name: 'LTV, $',
    identifier: 'LTV_SUMMARY',
    layout: {
      xPosition: 5,
      yPosition: 0,
      width: 1,
      height: 2,
    },
    dashboardType: 'CASHFLOW',
  },
  {
    type: 'SummaryCard',
    name: 'ARPU, $',
    identifier: 'ARPU_SUMMARY',
    layout: {
      xPosition: 4,
      yPosition: 2,
      width: 1,
      height: 2,
    },
    dashboardType: 'CASHFLOW',
  },
  {
    type: 'SummaryCard',
    name: 'CAC, $',
    identifier: 'CAC_SUMMARY',
    layout: {
      xPosition: 5,
      yPosition: 2,
      width: 1,
      height: 2,
    },
    dashboardType: 'CASHFLOW',
  },
  {
    type: 'SummaryCard',
    name: 'CHURN RATE, %',
    identifier: 'CHURN_RATE_SUMMARY',
    layout: {
      xPosition: 4,
      yPosition: 4,
      width: 1,
      height: 2,
    },
    dashboardType: 'CASHFLOW',
  },
  {
    type: 'SummaryCard',
    name: 'LTV/CAC',
    identifier: 'LTV_CAC_SUMMARY',
    layout: {
      xPosition: 5,
      yPosition: 4,
      width: 1,
      height: 2,
    },
    dashboardType: 'CASHFLOW',
  },
  {
    type: 'HistogramCard',
    name: 'NET BURN RATE, $',
    options: {
      colors: ['#EE6F3a'],
    },
    identifier: 'NET_BURN_RATE',
    layout: {
      width: 4,
      height: 6,
      xPosition: 0,
      yPosition: 7,
    },
    dashboardType: 'CASHFLOW',
  },
  {
    type: 'RaceChart',
    name: 'TOP 7 EXPENSES, $',
    identifier: 'EXPENSES_VS_BUDGET',
    layout: {
      xPosition: 4,
      yPosition: 7,
      width: 2,
      height: 6,
    },
    dashboardType: 'CASHFLOW',
  },
  // {
  //   type: 'PieChartCard',
  //   name: 'ACTUAL VS BUDGET - OUTFLOW, $',
  //   identifier: 'AVB_OUTFLOW',
  //   layout: {
  //     width: 3,
  //     height: 3,
  //     xPosition: 3,
  //     yPosition: 6,
  //   },
  //   dashboardType: 'CASHFLOW',
  // },
  // {
  //   type: 'PieChartCard',
  //   name: 'ACTUAL VS BUDGET - INFLOW, $',
  //   identifier: 'AVB_INFLOW',
  //   layout: {
  //     width: 3,
  //     height: 3,
  //     xPosition: 0,
  //     yPosition: 6,
  //   },
  //   dashboardType: 'CASHFLOW',
  // },
  {
    type: 'HistogramCard',
    name: 'REVENUE PROJECTED VS ACTUAL, $',
    identifier: 'REVENUE',
    options: {
      colors: ['#74E1AD', '#2BBC62'],
    },
    layout: {
      xPosition: 0,
      yPosition: 13,
      width: 4,
      height: 6,
    },
    dashboardType: 'P_L',
  },
  {
    type: 'LineChartCard',
    name: 'Revenue vs Expenses growth, %',
    identifier: 'REVENUE_GROWTH',
    options: {
      percents: true,
      colors: ['#2BBC62', '#11B3E7'],
    },
    layout: {
      xPosition: 4,
      yPosition: 13,
      width: 2,
      height: 6,
    },
    dashboardType: 'P_L',
  },
  {
    type: 'HistogramCard',
    name: 'PROFIT & LOSS, $',
    identifier: 'PL',
    options: {
      colors: ['#46D07A', '#712bfe', '#1aa3e1', '#C4DB71'],
    },
    layout: {
      xPosition: 0,
      yPosition: 19,
      width: 4,
      height: 6,
    },
    dashboardType: 'P_L',
  },
  {
    type: 'DynamicTableCard',
    name: 'P&L Structure',
    identifier: 'PL_STRUCTURE_TABLE',
    layout: {
      xPosition: 4,
      yPosition: 19,
      width: 2,
      height: 6,
    },
    dashboardType: 'P_L',
  },
  {
    type: 'StackedBarCard',
    name: 'REVENUE STRUCTURE',
    identifier: 'COGS_OPEX',
    options: {
      colors: ['#804FE6', '#AB5AFB', '#C889DC', '#C4569D', '#E06999'],
    },
    layout: {
      xPosition: 0,
      yPosition: 25,
      width: 6,
      height: 6,
    },
    dashboardType: 'P_L',
  },
  {
    type: 'TableCard',
    name: 'COGS',
    identifier: 'COGS_TABLE',
    layout: {
      xPosition: 0,
      yPosition: 31,
      width: 3,
      height: 8,
    },
    dashboardType: 'P_L',
  },
  {
    type: 'TableCard',
    name: 'OPEX',
    identifier: 'OPEX_TABLE',
    layout: {
      xPosition: 4,
      yPosition: 31,
      width: 3,
      height: 8,
    },
    dashboardType: 'P_L',
  },
  {
    type: 'CombinedChartCard',
    name: 'Gross profit & Gross profit margin',
    identifier: 'MARGIN',
    options: {
      colors: ['#7B7BE3'],
    },
    layout: {
      width: 3,
      height: 6,
      xPosition: 0,
      yPosition: 39,
    },
    dashboardType: 'P_L',
  },
  {
    type: 'CombinedChartCard',
    name: 'Net profit & Net profit margin',
    identifier: 'NET_INCOME_PROFITABILITY',
    options: {
      colors: '#C4DB71',
      lineColor: '#428456',
    },
    layout: {
      width: 3,
      height: 6,
      xPosition: 4,
      yPosition: 39,
    },
    dashboardType: 'P_L',
  },
  {
    type: 'WaterfallCard',
    name: 'Test Waterfall',
    identifier: 'WATERFALL_1',
    layout: {
      width: 4,
      height: 6,
    },
    dashboardType: 'Test',
  },
  {
    type: 'UpdatedStackedChartCard',
    name: 'TEST REVENUE STRUCTURE',
    identifier: 'TEST_STACKED',
    layout: {
      width: 4,
      height: 6,
    },
    dashboardType: 'Test',
  },
];

export const cashflowDashboardSettings = [
  {
    type: 'HistogramCard',
    name: 'CASH BALANCE',
    options: {
      colors: ['#C4DB71'],
    },
    identifier: 'CASH_BALANCE',
    layout: {
      width: 4,
      height: 6,
    },
    dashboardType: 'GENERAL',
  },
  {
    type: 'RaceChart',
    name: 'ACCOUNTS, $',
    identifier: 'ACCOUNTS',
    options: {
      colors: ['#C4DB71'],
    },
    layout: {
      xPosition: 6,
      yPosition: 3,
      width: 2,
      height: 6,
    },
    dashboardType: 'GENERAL',
  },
  {
    type: 'HistogramCard',
    name: 'INFLOW VS OUTFLOW',
    identifier: 'INFLOW_OUTFLOW',
    layout: {
      width: 4,
      height: 6,
    },
    dashboardType: 'GENERAL',
    options: {
      colors: ['#864DFE', '#FF9500'],
    },
  },
  {
    type: 'HistogramCard',
    name: 'FREE CASH FLOW (FCF)',
    options: {
      colors: ['#7B7BE3'],
    },
    identifier: 'FREE_CASH_FLOW',
    layout: {
      width: 2,
      height: 6,
      xPosition: 6,
      yPosition: 3,
    },
    dashboardType: 'GENERAL',
  },
  {
    type: 'HistogramCard',
    name: 'NET BURN RATE',
    options: {
      colors: ['#EE6F30'],
    },
    identifier: 'BURN_RATE',
    layout: {
      width: 4,
      height: 6,
      xPosition: 0,
      yPosition: 6,
    },
    dashboardType: 'GENERAL',
  },
  {
    type: 'HistogramCard',
    name: 'RUNWAY',
    options: {
      colors: ['#FBBC04'],
    },
    identifier: 'RUNWAY',
    layout: {
      width: 2,
      height: 4,
      xPosition: 6,
      yPosition: 3,
    },
    dashboardType: 'GENERAL',
  },
  {
    type: 'BalanceSummaryCard',
    name: 'CASH END MONTH',
    identifier: 'CASH_END_MONTH',
    layout: {
      xPosition: 4,
      yPosition: 3,
      width: 1,
      height: 2,
    },
    dashboardType: 'GENERAL',
  },
  // {
  //   type: 'BalanceSummaryCard',
  //   name: 'FUNDS NEEDED',
  //   identifier: 'FUNDS_NEEDED',
  //   layout: {
  //     xPosition: 6,
  //     yPosition: 3,
  //     width: 1,
  //     height: 2,
  //   },
  //   dashboardType: 'GENERAL',
  // },
  {
    type: 'HistogramCard',
    name: 'OPERATING INFLOW BUDGET VS ACTUAL',
    identifier: 'OPERATING_INFLOW_ACTUAL',
    layout: {
      width: 4,
      height: 4,
    },
    dashboardType: 'OPERATING INFLOW',
    options: {
      colors: ['#864DFE80', '#864DFE'],
    },
  },
  {
    type: 'ComparisonSummaryCard',
    name: '',
    options: {
      firstName: 'BUDGET INFLOW',
      secondName: 'ACTUAL INFLOW',
    },
    identifier: 'BUDGET_ACTUAL_INFLOW',
    layout: {
      xPosition: 6,
      yPosition: 3,
      width: 2,
      height: 2,
    },
    dashboardType: 'OPERATING INFLOW',
  },
  {
    type: 'SummaryCard',
    name: 'ACCOUNT RECEIVABLES, $',
    identifier: 'ACCOUNT_RECEIVABLES',
    layout: {
      xPosition: 6,
      yPosition: 3,
      width: 2,
      height: 2,
    },
    dashboardType: 'OPERATING INFLOW',
  },
  {
    type: 'HistogramCard',
    name: 'OPERATING CASH INFLOW VS REVENUE',
    identifier: 'OPERATING_INFLOW_REVENUE',
    layout: {
      width: 4,
      height: 6,
    },
    dashboardType: 'OPERATING INFLOW',
    options: {
      colors: ['#864DFE', '#40C371'],
    },
  },
  {
    type: 'TopCategoriesBalanceCard',
    name: 'TOP 5 DEBTORS, $',
    identifier: 'TOP_DEBTORS',
    layout: {
      xPosition: 8,
      yPosition: 3,
      width: 2,
      height: 6,
    },
    dashboardType: 'OPERATING INFLOW',
  },
  {
    type: 'HistogramCard',
    name: 'OPERATING OUTFLOW BUDGET VS ACTUAL',
    identifier: 'OPERATING_OUTFLOW_ACTUAL',
    layout: {
      width: 4,
      height: 4,
    },
    dashboardType: 'OPERATING OUTFLOW',
    options: {
      colors: ['#FF950080', '#FF9500'],
    },
  },
  {
    type: 'ComparisonSummaryCard',
    name: '',
    options: {
      firstName: 'BUDGET OUTFLOW',
      secondName: 'ACTUAL OUTFLOW',
    },
    identifier: 'BUDGET_ACTUAL_OUTFLOW',
    layout: {
      xPosition: 6,
      yPosition: 3,
      width: 2,
      height: 2,
    },
    dashboardType: 'OPERATING OUTFLOW',
  },
  {
    type: 'SummaryCard',
    name: 'ACCOUNT PAYABLES, $',
    identifier: 'ACCOUNT_PAYABLES',
    layout: {
      xPosition: 6,
      yPosition: 3,
      width: 2,
      height: 2,
    },
    dashboardType: 'OPERATING OUTFLOW',
  },
  // {
  //   type: 'CategoriesTableCard',
  //   name: 'CATEGORY',
  //   identifier: 'BUDGET_ACTUAL_CATEGORY',
  //   layout: {
  //     xPosition: 0,
  //     yPosition: 10,
  //     width: 3,
  //     height: 6,
  //   },
  //   dashboardType: 'OPERATING OUTFLOW',
  // },
  {
    type: 'BalanceSummaryCard',
    name: 'FINANCING ACTIVITIES FCF, $',
    identifier: 'FINANCING_ACTIVITIES',
    layout: {
      xPosition: 0,
      yPosition: 0,
      width: 3,
      height: 2,
    },
    dashboardType: 'FINANCIAL AND INVESTING ACTIVITY',
  },
  {
    type: 'BalanceSummaryCard',
    name: 'INVESTING ACTIVITIES FCF, $',
    identifier: 'INVESTING_ACTIVITIES',
    layout: {
      xPosition: 6,
      yPosition: 0,
      width: 3,
      height: 2,
    },
    dashboardType: 'FINANCIAL AND INVESTING ACTIVITY',
  },
  {
    type: 'HistogramCard',
    name: 'FINANCIAL INFLOW VS OUTFLOW',
    identifier: 'FINANCIAL_INFLOW_OUTFLOW',
    layout: {
      width: 3,
      height: 6,
      xPosition: 0,
      yPosition: 2,
    },
    dashboardType: 'FINANCIAL AND INVESTING ACTIVITY',
    options: {
      colors: ['#1AA3E1', '#C4DB71'],
    },
  },
  {
    type: 'HistogramCard',
    name: 'INVESTING INFLOW VS OUTFLOW',
    identifier: 'INVESTING_INFLOW_OUTFLOW',
    layout: {
      width: 3,
      height: 6,
      xPosition: 6,
      yPosition: 2,
    },
    dashboardType: 'FINANCIAL AND INVESTING ACTIVITY',
    options: {
      colors: ['#46BDC6', '#EBE18A'],
    },
  },
];

export const profitAndLossDashboardSettings = [
  {
    type: 'HistogramCard',
    name: 'PROJECTED VS ACTUAL REVENUE, $',
    identifier: 'PROJECTED_ACTUAL_REVENUE',
    layout: {
      width: 6,
      height: 6,
      xPosition: 0,
      yPosition: 0,
    },
    dashboardType: 'PROFIT AND LOSS STATEMENT (P&L)',
    options: {
      colors: ['#2BBC62', '#2BBC6280'],
    },
  },
  {
    type: 'HistogramCard',
    name: 'PROJECTED VS ACTUAL NET PROFIT, $',
    identifier: 'PROJECTED_ACTUAL_NET_PROFIT',
    layout: {
      width: 6,
      height: 6,
      xPosition: 0,
      yPosition: 6,
    },
    dashboardType: 'PROFIT AND LOSS STATEMENT (P&L)',
    options: {
      colors: ['#EC4862', '#F9ACB8'],
    },
  },
  {
    type: 'WaterfallCard',
    name: 'P&L WATERFALL, $',
    identifier: 'PL_WATERFALL',
    layout: {
      width: 4,
      height: 6,
    },
    dashboardType: 'PROFIT AND LOSS STATEMENT (P&L)',
  },
  // {
  //   type: 'GroupedTableCard',
  //   name: 'P&L STRUCTURE, %',
  //   identifier: 'PL_STRUCTURE',
  //   layout: {
  //     xPosition: 3,
  //     yPosition: 10,
  //     width: 3,
  //     height: 6,
  //   },
  //   dashboardType: 'PROFIT AND LOSS STATEMENT (P&L)',
  // },
  {
    type: 'StackedBarCard',
    name: 'REVENUE STREAM BREAKDOWN, $',
    identifier: 'PROJECTED_ACTUAL_REVENUE_STREAMS_BREAKDOWN',
    options: {
      colors: ['#2BBC62', '#2BBC62B2', '#2BBC6280', '#2BBC624D'],
    },
    layout: {
      xPosition: 0,
      yPosition: 6,
      width: 6,
      height: 6,
    },
    dashboardType: 'REVENUE',
  },
  {
    type: 'LineChartCard',
    name: 'PROJECTED VS ACTUAL REVENUE GROWTH, %',
    identifier: 'PROJECTED_ACTUAL_REVENUE_GROWTH_PERCENT',
    options: {
      percents: true,
      colors: ['#46D07A', '#5F6368'],
    },
    layout: {
      xPosition: 0,
      yPosition: 13,
      width: 3,
      height: 6,
    },
    dashboardType: 'REVENUE',
  },
  {
    type: 'AreaChartCard',
    name: 'PROJECTED VS ACTUAL REVENUE GROWTH, $',
    identifier: 'PROJECTED_ACTUAL_REVENUE_GROWTH_SUM',
    options: {
      percents: true,
      colors: ['#46D07A9E', '#5F636852'],
    },
    layout: {
      xPosition: 4,
      yPosition: 13,
      width: 3,
      height: 6,
    },
    dashboardType: 'REVENUE',
  },
  {
    type: 'HistogramCard',
    name: 'PROJECTED VS ACTUAL GROSS PROFIT MARGIN',
    identifier: 'PROJECTED_ACTUAL_GROSS_PROFIT_MARGIN',
    layout: {
      width: 6,
      height: 6,
      xPosition: 0,
      yPosition: 19,
    },
    dashboardType: 'GROSS PROFIT MARGIN  &  COST OF GOODS SOLD (COGS)',
    options: {
      colors: ['#E069C0', '#E069C080'],
    },
  },

  {
    type: 'HistogramCard',
    name: 'PROJECTED VS ACTUAL COGS',
    identifier: 'PROJECTED_ACTUAL_COGS',
    layout: {
      width: 6,
      height: 6,
      xPosition: 0,
      yPosition: 25,
    },
    dashboardType: 'GROSS PROFIT MARGIN  &  COST OF GOODS SOLD (COGS)',
    options: {
      colors: ['#864DFE80', '#864DFE'],
    },
  },
  // // {
  // //   type: 'TableCard',
  // //   name: 'COGS',
  // //   identifier: 'PL_COGS_TABLE',
  // //   layout: {
  // //     xPosition: 0,
  // //     yPosition: 10,
  // //     width: 3,
  // //     height: 6,
  // //   },
  // //   dashboardType: 'GROSS PROFIT MARGIN  &  COST OF GOODS SOLD (COGS)',
  // // },
  {
    type: 'LineChartCard',
    name: 'REVENUE GROWTH VS COGS GROWTH',
    identifier: 'REVENUE_COGS_GROWTH',
    options: {
      percents: true,
      colors: ['#46D07A', '#864DFEB2'],
    },
    layout: {
      xPosition: 3,
      yPosition: 25,
      width: 3,
      height: 6,
    },
    dashboardType: 'GROSS PROFIT MARGIN  &  COST OF GOODS SOLD (COGS)',
  },
  // // {
  // //   type: 'StackedBarCard',
  // //   name: 'PROJECTED VS ACTUAL OPEX BREAKDOWN, $',
  // //   identifier: 'PROJECTED_ACTUAL_OPEX_BREAKDOWN',
  // //   options: {
  // //     colors: ['#1AA3E1', '#1AA3E1BF', '#1AA3E180', '#1AA3E145'],
  // //   },
  // //   layout: {
  // //     xPosition: 0,
  // //     yPosition: 7,
  // //     width: 6,
  // //     height: 6,
  // //   },
  // //   dashboardType: 'OPERATIONAL EXPENSES (OPEX)',
  // // },
  {
    type: 'SummaryCard',
    name: 'TOTAL OPEX',
    identifier: 'OPEX_SUMMARY',
    layout: {
      xPosition: 4,
      yPosition: 31,
      width: 2,
      height: 2,
    },
    dashboardType: 'OPERATIONAL EXPENSES (OPEX)',
  },
  {
    type: 'LineChartCard',
    name: 'REVENUE GROWTH VS OPEX GROWTH',
    identifier: 'REVENUE_OPEX_GROWTH',
    options: {
      percents: true,
      colors: ['#46D07A', '#1AA3E1'],
    },
    layout: {
      xPosition: 0,
      yPosition: 33,
      width: 4,
      height: 6,
    },
    dashboardType: 'OPERATIONAL EXPENSES (OPEX)',
  },
  // // {
  // //   type: 'StackedBarCard',
  // //   name: 'PROJECTED VS ACTUAL TEAM BREAKDOWN, $',
  // //   identifier: 'PROJECTED_ACTUAL_TEAM_BREAKDOWN',
  // //   options: {
  // //     colors: ['#1AA3E1', '#1AA3E1BF', '#1AA3E180', '#1AA3E145'],
  // //   },
  // //   layout: {
  // //     xPosition: 0,
  // //     yPosition: 7,
  // //     width: 6,
  // //     height: 6,
  // //   },
  // //   dashboardType: 'OPERATIONAL EXPENSES (OPEX)',
  // // },
  // // {
  // //   type: 'TableCard',
  // //   name: 'PROJECTED VS ACTUAL',
  // //   identifier: 'PROJECTED_ACTUAL_TEAM_BREAKDOWN_TABLE',
  // //   layout: {
  // //     xPosition: 0,
  // //     yPosition: 10,
  // //     width: 3,
  // //     height: 6,
  // //   },
  // //   dashboardType: 'OPERATIONAL EXPENSES (OPEX)',
  // // },
  {
    type: 'HistogramCard',
    name: 'EBITDA',
    identifier: 'EBITDA',
    layout: {
      width: 6,
      height: 6,
      xPosition: 0,
      yPosition: 39,
    },
    dashboardType: 'EBITDA & NET PROFIT',
    options: {
      colors: ['#BC2B93'],
    },
  },
  {
    type: 'CombinedChartCard',
    name: 'NET PROFIT & NET PROFIT MARGIN',
    identifier: 'NET_PROFIT_MARGIN',
    layout: {
      width: 6,
      height: 6,
      xPosition: 0,
      yPosition: 45,
    },
    dashboardType: 'EBITDA & NET PROFIT',
    options: {
      colors: '#EC4862',
      lineColor: '#5F6368',
    },
  },
];

export const revenueAnalysisDashboardSettings = [
  {
    type: 'HistogramCard',
    name: 'REVENUE',
    identifier: 'REVENUE_ANALYSIS',
    layout: {
      width: 3,
      height: 6,
      xPosition: 0,
      yPosition: 0,
    },
    dashboardType: 'REVENUE',
    options: {
      colors: ['#2BBC6280', '#2BBC62'],
    },
  },
  {
    type: 'LineChartCard',
    name: 'REVENUE GROWTH',
    identifier: 'REVENUE_GROWTH_ANALYSIS',
    options: {
      percents: true,
      colors: ['#2BBC62', '#11B3E7'],
    },
    layout: {
      xPosition: 3,
      yPosition: 0,
      width: 3,
      height: 6,
    },
    dashboardType: 'REVENUE',
  },
  // {
  //   type: 'StackedBarCard',
  //   name: 'TEAM BREAKDOWN, $',
  //   identifier: 'PROJECTED_ACTUAL_TEAM_BREAKDOWN',
  //   options: {
  //     colors: ['#8CD1F0', '#EE6F30'],
  //   },
  //   layout: {
  //     xPosition: 0,
  //     yPosition: 7,
  //     width: 6,
  //     height: 6,
  //   },
  //   dashboardType: 'REVENUE',
  // },
  {
    type: 'SplitSummaryCard',
    name: 'NEW REVENUE',
    options: {
      color: '#2BBC62',
    },
    identifier: 'NEW_REVENUE',
    layout: {
      xPosition: 0,
      yPosition: 7,
      width: 3,
      height: 2,
    },
    dashboardType: 'REVENUE',
  },
  {
    type: 'SplitSummaryCard',
    name: 'CHURN REVENUE',
    options: {
      color: '#EC4862',
    },
    identifier: 'CHURN_REVENUE',
    layout: {
      xPosition: 3,
      yPosition: 7,
      width: 3,
      height: 2,
    },
    dashboardType: 'REVENUE',
  },
  {
    type: 'StackedBarCard',
    name: 'MRR BREAKDOWN',
    identifier: 'MRR_BREAKDOWN',
    options: {
      colors: ['#00A743', '#EEC30A', '#EC4862'],
    },
    layout: {
      xPosition: 0,
      yPosition: 13,
      width: 6,
      height: 6,
    },
    dashboardType: 'REVENUE',
  },
  {
    type: 'StackedBarCard',
    name: 'CHURN BREAKDOWN',
    identifier: 'CHURN_BREAKDOWN',
    options: {
      colors: ['#00A743', '#B3E5C7', '#F9C8D0', '#EC4862'],
    },
    layout: {
      xPosition: 0,
      yPosition: 19,
      width: 6,
      height: 6,
    },
    dashboardType: 'REVENUE',
  },
  {
    type: 'HistogramCard',
    name: 'CHURN RATE FOR LOST MRR',
    identifier: 'CHURN_RATE_FOR_LOST_MRR_1',
    layout: {
      width: 6,
      height: 6,
      xPosition: 0,
      yPosition: 25,
    },
    dashboardType: 'REVENUE',
    options: {
      colors: ['#F5A3B0', '#EC4862'],
      withFixedValue: 0,
      percents: true,
    },
  },

  {
    type: 'HistogramCard',
    name: 'USERS (EOP)',
    options: {
      colors: ['#8CD1F0', '#1AA3E1'],
      withFixedValue: 0,
    },
    identifier: 'USERS_EOP',
    layout: {
      width: 3,
      height: 6,
      xPosition: 0,
      yPosition: 31,
    },
    dashboardType: 'USERS',
  },
  {
    type: 'HistogramCard',
    name: 'NEW USERS (EOP)',
    options: {
      colors: ['#95DDB0', '#00A743'],
      withFixedValue: 0,
    },
    identifier: 'NEW_USERS_EOP',
    layout: {
      width: 3,
      height: 6,
      xPosition: 4,
      yPosition: 31,
    },
    dashboardType: 'USERS',
  },
  {
    type: 'HistogramCard',
    name: 'ARPU',
    options: {
      colors: ['#864DFE', '#C2A6FE'],
      withFixedValue: 0,
    },
    identifier: 'ARPU',
    layout: {
      width: 3,
      height: 6,
      xPosition: 0,
      yPosition: 37,
    },
    dashboardType: 'USERS',
  },
  {
    type: 'HistogramCard',
    name: 'ARPU PER NEW USER',
    options: {
      colors: ['#864DFE', '#C2A6FE'],
    },
    identifier: 'ARPU_PER_NEW_USER',
    layout: {
      width: 3,
      height: 6,
      xPosition: 4,
      yPosition: 37,
    },
    dashboardType: 'USERS',
  },
  {
    type: 'LineChartCard',
    name: 'ARPU BY PRODUCTS, $',
    identifier: 'ARPU_BY_PRODUCTS_LINE',
    options: {
      percents: false,
      colors: ['#EC4862', '#1AA3E1', '#864DFE', '#00A743', '#EEC30A'],
      legendPosition: 'right',
    },
    layout: {
      width: 4,
      height: 6,
      xPosition: 0,
      yPosition: 43,
    },
    dashboardType: 'USERS',
  },
  {
    type: 'BarChartByCategoryCard',
    name: 'ARPU BY PRODUCTS, $',
    options: {
      colors: ['#EC4862', '#1AA3E1', '#864DFE', '#2BBC62', '#EEC30A'],
    },
    identifier: 'ARPU_BY_PRODUCTS',
    layout: {
      width: 2,
      height: 6,
      xPosition: 4,
      yPosition: 43,
    },
    dashboardType: 'USERS',
  },
  {
    type: 'StackedBarCard',
    name: 'USERS BREAKDOWN',
    identifier: 'USERS_BREAKDOWN',
    options: {
      colors: ['#00A743', '#EEC30A', '#EC4862'],
    },
    layout: {
      xPosition: 0,
      yPosition: 49,
      width: 4,
      height: 6,
    },
    dashboardType: 'USERS',
  },
  {
    type: 'SummaryCard',
    name: 'NEW USERS',
    identifier: 'NEW_USERS_SUMMARY',
    layout: {
      xPosition: 4,
      yPosition: 49,
      width: 2,
      height: 2,
    },
    dashboardType: 'USERS',
  },
  {
    type: 'SummaryCard',
    name: 'EXISTING USERS',
    identifier: 'EXISTING_USERS_SUMMARY',
    layout: {
      xPosition: 4,
      yPosition: 51,
      width: 2,
      height: 2,
    },
    dashboardType: 'USERS',
  },
  {
    type: 'SummaryCard',
    name: 'CHURN USERS',
    identifier: 'CHURN_USERS_SUMMARY',
    layout: {
      xPosition: 4,
      yPosition: 53,
      width: 2,
      height: 2,
    },
    dashboardType: 'USERS',
  },
  {
    type: 'HistogramCard',
    name: 'CHURN RATE FOR Q-TY OF USERS',
    options: {
      colors: ['#F5A3B0', '#EC4862'],
      withFixedValue: 0,
      percents: true,
    },
    identifier: 'CHURN_RATE_FOR_QTY_OF_USERS',
    layout: {
      width: 6,
      height: 6,
      xPosition: 0,
      yPosition: 55,
    },
    dashboardType: 'CHURN & RETENTION',
  },
  {
    type: 'SummaryCard',
    name: 'LOST MRR, %',
    identifier: 'LOST_MRR',
    layout: {
      xPosition: 0,
      yPosition: 61,
      width: 2,
      height: 2,
    },
    dashboardType: 'CHURN & RETENTION',
  },
  {
    type: 'SummaryCard',
    name: '3 MONTH AVG (LOST MRR, %)',
    identifier: 'THREE_MONTH_AVG_LOST_MRR',
    layout: {
      xPosition: 0,
      yPosition: 63,
      width: 2,
      height: 2,
    },
    dashboardType: 'CHURN & RETENTION',
  },
  {
    type: 'SummaryCard',
    name: 'LOST USERS, %',
    identifier: 'LOST_USERS_SUMMARY',
    layout: {
      xPosition: 0,
      yPosition: 65,
      width: 2,
      height: 2,
    },
    dashboardType: 'CHURN & RETENTION',
  },
  {
    type: 'HistogramCard',
    name: 'CHURN RATE FOR LOST MRR',
    options: {
      colors: ['#F5A3B0', '#EC4862'],
      withFixedValue: 0,
      percents: true,
    },
    identifier: 'CHURN_RATE_FOR_LOST_MRR_2',
    layout: {
      xPosition: 3,
      yPosition: 61,
      width: 4,
      height: 6,
    },
    dashboardType: 'CHURN & RETENTION',
  },
  {
    type: 'LineChartCard',
    name: 'REVENUE CURVE',
    identifier: 'REVENUE_CURVE',
    options: {
      percents: true,
      colors: ['#BC2B93'],
    },
    layout: {
      xPosition: 0,
      yPosition: 67,
      width: 5,
      height: 4,
    },
    dashboardType: 'CHURN & RETENTION',
  },
  {
    type: 'BalanceSummaryCard',
    name: 'YEARLY RET.RATE',
    identifier: 'YEARLY_RET_RATE',
    layout: {
      xPosition: 5,
      yPosition: 67,
      width: 1,
      height: 2,
    },
    dashboardType: 'CHURN & RETENTION',
  },
  {
    type: 'BalanceSummaryCard',
    name: 'YEARLY CHURN RATE',
    identifier: 'YEARLY_CHURN_RATE',
    layout: {
      xPosition: 5,
      yPosition: 69,
      width: 1,
      height: 2,
    },
    dashboardType: 'CHURN & RETENTION',
  },
  {
    type: 'HeatmapChartCard',
    name: 'Retention by products',
    identifier: 'COGS_HEATMAP',
    options: {
      colors: ['#C955A9'],
    },
    layout: {
      xPosition: 0,
      yPosition: 71,
      width: 4,
      height: 6,
    },
    dashboardType: 'CHURN & RETENTION',
  },
  {
    type: 'BarChartByCategoryCard',
    name: 'YEARLY CHURN RATE BY PRODUCTS',
    options: {
      colors: ['#EC4862', '#1AA3E1', '#864DFE', '#2BBC62', '#EEC30A'],
    },
    identifier: 'YEARLY_CHURN_RATE_BY_PRODUCTS',
    layout: {
      width: 2,
      height: 6,
      xPosition: 4,
      yPosition: 71,
    },
    dashboardType: 'CHURN & RETENTION',
  },
  {
    type: 'BarChartByCategoryCard',
    name: 'LTV BY PRODUCTS',
    options: {
      colors: ['#EC4862', '#1AA3E1', '#864DFE', '#2BBC62', '#EEC30A'],
    },
    identifier: 'LTV_BY_PRODUCTS',
    layout: {
      width: 2,
      height: 6,
      xPosition: 0,
      yPosition: 77,
    },
    dashboardType: 'LTV',
  },
  {
    type: 'HistogramCard',
    name: 'LTV',
    options: {
      colors: ['#8CD1F0', '#1AA3E1'],
    },
    identifier: 'LTV',
    layout: {
      width: 4,
      height: 6,
      xPosition: 3,
      yPosition: 77,
    },
    dashboardType: 'LTV',
  },
  {
    type: 'BarChartByCategoryCard',
    name: 'LT BY PRODUCTS',
    options: {
      colors: ['#EC4862', '#1AA3E1', '#864DFE', '#2BBC62', '#EEC30A'],
    },
    identifier: 'LT_BY_PRODUCTS',
    layout: {
      width: 2,
      height: 6,
      xPosition: 0,
      yPosition: 83,
    },
    dashboardType: 'LTV',
  },
  {
    type: 'HistogramCard',
    name: 'AVG LT',
    options: {
      colors: ['#8CD1F0', '#1AA3E1'],
      withFixedValue: 0,
    },
    identifier: 'AVG_LT',
    layout: {
      width: 4,
      height: 6,
      xPosition: 3,
      yPosition: 83,
    },
    dashboardType: 'LTV',
  },
  {
    type: 'HistogramCard',
    name: 'LTV:CAC',
    identifier: 'LTV_CAC_COMBINED',
    options: {
      colors: ['#864DFE', '#1AA3E1', '#BC2B93'],
    },
    layout: {
      width: 4,
      height: 6,
      xPosition: 0,
      yPosition: 89,
    },
    dashboardType: 'LTV',
  },
  {
    type: 'SummaryCard',
    name: 'LT',
    identifier: 'LT_SUMMARY_REVENUE',
    layout: {
      width: 2,
      height: 2,
      xPosition: 4,
      yPosition: 89,
    },
    dashboardType: 'LTV',
  },
  {
    type: 'SummaryCard',
    name: 'LTV',
    identifier: 'LTV_SUMMARY_REVENUE',
    layout: {
      width: 2,
      height: 2,
      xPosition: 4,
      yPosition: 91,
    },
    dashboardType: 'LTV',
  },
  {
    type: 'SummaryCard',
    name: 'LTV:CAC',
    identifier: 'LTV_CAC_SUMMARY_REVENUE',
    layout: {
      width: 2,
      height: 2,
      xPosition: 4,
      yPosition: 93,
    },
    dashboardType: 'LTV',
  },
];
