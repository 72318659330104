import { AxiosData } from '../../api-services/interfaces';
import { PaginatedType } from '../../base-types';

export type SourceType = 'ACCOUNT' | 'EMPLOYEE' | 'CLIENT' | 'PRODUCT';

export type IntegrationMapping = {
  integrationId: number;
  sourceType: SourceType;
  integrationName: string;
  mappings: { from: string; to: string }[];
};

export enum IntegrationName {
  QUICKBOOKS = 'Quickbooks',
}

export type MappingSource = {
  integrationName: IntegrationName;
  integrationId: string;
};

export type Settings = {
  accountType?: string;
  cacCalculation?: boolean;
  deliveryTeam?: boolean;
  startDate?: string;
  endDate?: string;
};

export type MappingMasterList = {
  id: number;
  name: string;
  settings?: Settings;
  group?: { name: string; id: number };
  companyId: number;
};

export type MappingMasterListPaginatedResponse = AxiosData<
  PaginatedType<MappingMasterList>
>;

export type AccountGroup = {
  id: number;
  name: string;
  type?: { id: number; name: string };
  source?: AccountGroupSource;
};

export type AccountGroupPaginatedResponse = AxiosData<
  PaginatedType<AccountGroup>
>;

export type Mapping = {
  id: number;
  sourceType: SourceType;
  integrationId: number;
  integrationName: string;
  name: string;
  masterList: MappingMasterList | null;
};

export type MappingPaginatedResponse = AxiosData<PaginatedType<Mapping>>;

export enum AccountGroupSource {
  MANUAL = 'Manual',
  FUEL = 'Fuel',
  QUICKBOOKS = 'Quickbooks',
}

export type IMappingPagination = {
  page: number;
  limit: number;
  search: string;
  sorting: { key: string; order: 'asc' | 'desc' }[];
};
