import { ResponsiveBar, ComputedBarDatum } from '@nivo/bar';
import { HistogramDataset } from '../../../../hooks/client/dashboard/interfaces/dashboard-card.type';
import { useContext } from 'react';
import { DrilldownContext } from '../../../../hooks/client/dashboard/drill-down/drill-down.context';
import {
  convertToDateRange,
  reformatToThousandsWithoutSign,
} from '../../../../utils/dashboard/formatters';

const BarChartByCategory = ({
  dataset,
  options,
  name,
  identifier,
}: {
  dataset: HistogramDataset;
  options?: { colors?: string[]; percents?: boolean };
  name: string;
  identifier: string;
}) => {
  const drillDownContext = useContext(DrilldownContext);

  const handleClick = (event: any, bar: any) => {
    event.stopPropagation();

    const date = bar.indexValue;

    if (drillDownContext?.setData) {
      drillDownContext.setData({
        date: convertToDateRange(date),
        name: name || '',
        identifier: identifier || '',
      });
    }
  };

  const CustomLabelLayer = ({
    bars,
  }: {
    bars: readonly ComputedBarDatum<any>[];
  }) => (
    <>
      {bars.map((bar) => (
        <text
          key={bar.key}
          x={bar.x + bar.width / 2}
          y={bar.y - 10}
          textAnchor="middle"
          style={{
            fill: '#333',
            fontSize: 12,
            fontWeight: 'bold',
          }}
        >
          {reformatToThousandsWithoutSign(bar.data.value || 0)}
        </text>
      ))}
    </>
  );

  return (
    <div className="drag-cancel" style={{ width: '100%', height: '100%' }}>
      <ResponsiveBar
        data={dataset.data}
        keys={dataset.keys}
        indexBy={dataset.indexBy}
        margin={{ top: 50, right: 20, bottom: 100, left: 20 }}
        padding={0}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={(bar) =>
          options?.colors
            ? options.colors[bar.index % options.colors.length]
            : 'nivo'
        }
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        onClick={(bar, event) => handleClick(event, bar)}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: 32,
          renderTick: (tick) => {
            const maxCharsPerLine = 8;
            const words = tick.value.split(' ') as string[];
            const textLines = [];
            let currentLine = '';

            words.forEach((word) => {
              if ((currentLine + word).length > maxCharsPerLine) {
                textLines.push(currentLine.trim());
                currentLine = word + ' ';
              } else {
                currentLine += word + ' ';
              }
            });

            if (currentLine) {
              textLines.push(currentLine.trim());
            }

            return (
              <g transform={`translate(${tick.x},${tick.y + 20})`}>
                {textLines.map((line, index) => (
                  <text
                    key={index}
                    textAnchor="middle"
                    dy={`${index * 1.2}em`}
                    style={{
                      fill: '#333',
                      fontSize: 11,
                    }}
                  >
                    {line}
                  </text>
                ))}
              </g>
            );
          },
        }}
        axisLeft={null}
        label={''}
        labelSkipWidth={12}
        labelSkipHeight={12}
        enableGridY={false}
        role="application"
        ariaLabel={`${name} bar chart`}
        barAriaLabel={(e) => `${e.id}: ${e.formattedValue} on ${e.indexValue}`}
        layers={[
          'grid',
          'axes',
          'bars',
          CustomLabelLayer,
          'markers',
          'legends',
        ]}
      />
    </div>
  );
};

export default BarChartByCategory;
