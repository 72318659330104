import {
  BalanceSummaryDataset,
  HistogramDataset,
  RevenueAnalysisDashboardDataType,
  SummaryDataset,
} from '../interfaces/dashboard-card.type';
import { DateTime } from 'luxon';
import {
  formatDate,
  reverseSign,
} from '../../../../utils/dashboard/formatters';
import {
  DashboardDataItem,
  DashboardDataItemWithCategory,
  DashboardDataItemWithProduct,
  YearlyProductDataItem,
} from '../interfaces/dashboard-data-item.interface';
import { getLastClosedMonth } from '../utils';
import { RevenueAnalysisDashboardData } from '../interfaces/revenue-analysis-data.interface';
import { it } from 'vitest';

interface DateEntry {
  date: string;
  [category: string]: number | string;
}

export class RevenueAnalysisDashboardDataManager {
  private date: string | null = null;
  private startDate: Date | null = null;
  private endDate: Date | null = null;
  private methodMap: Map<RevenueAnalysisDashboardDataType, any>;

  constructor(private readonly dashboardData: RevenueAnalysisDashboardData) {
    this.methodMap = new Map<RevenueAnalysisDashboardDataType, any>([
      [RevenueAnalysisDashboardDataType.REVENUE_ANALYSIS, this.getRevenueData],
      [
        RevenueAnalysisDashboardDataType.REVENUE_GROWTH_ANALYSIS,
        this.getRevenueGrowthData,
      ],
      [RevenueAnalysisDashboardDataType.NEW_REVENUE, this.getNewRevenueData],
      [
        RevenueAnalysisDashboardDataType.CHURN_REVENUE,
        this.getChurnRevenueData,
      ],
      [
        RevenueAnalysisDashboardDataType.MRR_BREAKDOWN,
        this.getMrrBreakdownData,
      ],
      [
        RevenueAnalysisDashboardDataType.CHURN_BREAKDOWN,
        this.getChurnBreakdownData,
      ],
      [RevenueAnalysisDashboardDataType.USERS_EOP, this.getUsersEopData],
      [RevenueAnalysisDashboardDataType.NEW_USERS_EOP, this.getNewUsersEopData],
      [RevenueAnalysisDashboardDataType.ARPU, this.getArpuData],
      [
        RevenueAnalysisDashboardDataType.ARPU_PER_NEW_USER,
        this.getArpuPerNewUserData,
      ],
      [
        RevenueAnalysisDashboardDataType.CHURN_RATE_FOR_LOST_MRR_1,
        this.getChurnRateForLostMrrData,
      ],
      [
        RevenueAnalysisDashboardDataType.ARPU_BY_PRODUCTS_LINE,
        this.getArpuByProductsLine,
      ],
      [
        RevenueAnalysisDashboardDataType.ARPU_BY_PRODUCTS,
        this.getArpuByProducts,
      ],
      [
        RevenueAnalysisDashboardDataType.USERS_BREAKDOWN,
        this.getUsersBreakdownData,
      ],
      [
        RevenueAnalysisDashboardDataType.NEW_USERS_SUMMARY,
        this.getNewUsersSummaryData,
      ],
      [
        RevenueAnalysisDashboardDataType.EXISTING_USERS_SUMMARY,
        this.getExistingUsersSummaryData,
      ],
      [
        RevenueAnalysisDashboardDataType.CHURN_USERS_SUMMARY,
        this.getChurnedCustomersSummaryData,
      ],
      [
        RevenueAnalysisDashboardDataType.CHURN_RATE_FOR_QTY_OF_USERS,
        this.getChurnRateForQuantityOfUsers,
      ],
      [RevenueAnalysisDashboardDataType.LOST_MRR, this.getLostMrrSummaryData],
      [
        RevenueAnalysisDashboardDataType.THREE_MONTH_AVG_LOST_MRR,
        this.getThreeMonthAvgLostMrrSummaryData,
      ],
      [
        RevenueAnalysisDashboardDataType.LOST_USERS_SUMMARY,
        this.getLostUsersSummaryData,
      ],
      [
        RevenueAnalysisDashboardDataType.CHURN_RATE_FOR_LOST_MRR_2,
        this.getChurnRateForLostMrrData,
      ],
      [
        RevenueAnalysisDashboardDataType.REVENUE_CURVE,
        this.getRevenueCurveData,
      ],
      [
        RevenueAnalysisDashboardDataType.YEARLY_RET_RATE,
        this.getYearlyRetentionRateSummaryData,
      ],
      [
        RevenueAnalysisDashboardDataType.YEARLY_CHURN_RATE,
        this.getYearlyChurnRateSummaryData,
      ],
      [RevenueAnalysisDashboardDataType.COGS_HEATMAP, this.getCOGSHeatmapData],
      [
        RevenueAnalysisDashboardDataType.YEARLY_CHURN_RATE_BY_PRODUCTS,
        this.getChurnRateByProducts,
      ],
      [RevenueAnalysisDashboardDataType.LTV_BY_PRODUCTS, this.getLTVByProducts],
      [RevenueAnalysisDashboardDataType.LTV, this.getLtvData],
      [RevenueAnalysisDashboardDataType.LT_BY_PRODUCTS, this.getLTByProducts],
      [RevenueAnalysisDashboardDataType.AVG_LT, this.getAvgLtData],
      [
        RevenueAnalysisDashboardDataType.LTV_CAC_COMBINED,
        this.getLtvCacCombinedData,
      ],
      [
        RevenueAnalysisDashboardDataType.LT_SUMMARY_REVENUE,
        this.getLTSummaryData,
      ],
      [
        RevenueAnalysisDashboardDataType.LTV_SUMMARY_REVENUE,
        this.getLTVSummaryData,
      ],
      [
        RevenueAnalysisDashboardDataType.LTV_CAC_SUMMARY_REVENUE,
        this.getLTVCACSummaryData,
      ],
    ]);
  }

  public setDate(date: string) {
    this.date = date;
  }

  public setDateRange(startDate: Date | null, endDate: Date | null) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  public getHeaderData(key: keyof RevenueAnalysisDashboardData) {
    const data = this.dashboardData[key];

    if (!this.isDashboardDataItemArray(data)) {
      throw new Error('Invalid data type');
    }
    const { month: targetMonth, year: targetYear } = getLastClosedMonth(
      this.endDate
    );
    const monthData = this.getDateFormMonthData(data).find(
      (d) => d.month === targetMonth && d.year === targetYear
    );

    const prevMonthData = data.find(
      (item) => item.year === targetYear && item.month === targetMonth - 1
    );

    if (!monthData) {
      return {
        month: targetMonth,
        year: targetYear,
        monthData: {
          value: 0,
        },
        prevMonthData: {
          value: prevMonthData?.value || 0,
        },
      };
    }

    return {
      month: monthData.month || 0,
      year: monthData.year,
      monthData: {
        value: monthData.value || 0,
      },
      prevMonthData: {
        value: prevMonthData?.value || 0,
      },
    };
  }

  public getThreeMonthAverage(key: keyof RevenueAnalysisDashboardData) {
    const data = this.dashboardData[key];

    if (!this.isDashboardDataItemArray(data)) {
      throw new Error('Invalid data type');
    }

    const { month: targetMonth, year: targetYear } = getLastClosedMonth(
      this.endDate
    );
    const endDate = new Date(targetYear, targetMonth - 1);

    const currentThreeMonths = [0, 1, 2].map((offset) => {
      const date = new Date(endDate.getFullYear(), endDate.getMonth() - offset);
      const monthData = data.find(
        (d) => d.year === date.getFullYear() && d.month === date.getMonth() + 1
      );
      return monthData?.value || 0;
    });
    const currentAverage =
      currentThreeMonths.reduce((sum, value) => sum + value, 0) /
      currentThreeMonths.length;

    const previousThreeMonths = [3, 4, 5].map((offset) => {
      const date = new Date(endDate.getFullYear(), endDate.getMonth() - offset);
      const monthData = data.find(
        (d) => d.year === date.getFullYear() && d.month === date.getMonth() + 1
      );
      return monthData?.value || 0;
    });
    const prevAverage =
      previousThreeMonths.reduce((sum, value) => sum + value, 0) /
      previousThreeMonths.length;

    return {
      month: targetMonth,
      year: targetYear,
      monthData: {
        value: parseFloat(currentAverage.toFixed(2)) || 0,
      },
      prevMonthData: {
        value: parseFloat(prevAverage.toFixed(2)) || 0,
      },
    };
  }

  public getData(type: RevenueAnalysisDashboardDataType) {
    const method = this.methodMap.get(type);
    if (!method) {
      console.warn(`No method available for type: ${type}`);
      return null;
    }
    const result = method();
    return result;
  }

  private getDateFormMonthData(
    dataItems:
      | DashboardDataItem[]
      | DashboardDataItemWithCategory[]
      | DashboardDataItemWithProduct[]
  ):
    | DashboardDataItem[]
    | DashboardDataItemWithCategory[]
    | DashboardDataItemWithProduct[] {
    if (!dataItems || dataItems.length === 0) {
      return [];
    }

    if (this.startDate && this.endDate) {
      return dataItems?.filter((item) => {
        const itemDate = new Date(item.year, item.month - 1);

        const isSameMonthAsStart =
          itemDate.getFullYear() === this.startDate?.getFullYear() &&
          itemDate.getMonth() === this.startDate.getMonth();
        const isSameMonthAsEnd =
          itemDate.getFullYear() === this.endDate?.getFullYear() &&
          itemDate.getMonth() === this.endDate.getMonth();

        return (
          isSameMonthAsStart ||
          isSameMonthAsEnd ||
          (this.startDate &&
            this.endDate &&
            itemDate >= this.startDate &&
            itemDate <= this.endDate)
        );
      });
    }

    if (this.date) {
      return dataItems.filter((it) => {
        return `${it.year}-${it.month}` === this.date;
      });
    }

    return dataItems;
  }

  public getRevenueData = (): HistogramDataset | null => {
    return this.getProjectedActualData(
      this.dashboardData.revenueActualMetrics,
      this.dashboardData.revenueProjectedMetrics,
      'actual, $',
      'projected, $'
    );
  };

  public getChurnRateForLostMrrData = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.churnRateInNumberMetrics,
      [],
      'actual churn rate, %',
      'projected churn rate, %'
    );
  };

  public getUsersEopData = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.activeCustomersMetrics,
      [],
      'actual',
      'projected'
    );
  };

  public getNewUsersEopData = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.newCustomersActualMetrics,
      [],
      'actual',
      'projected'
    );
  };

  public getArpuData = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.newCustomersActualMetrics,
      [],
      'actual',
      'projected'
    );
  };

  public getArpuPerNewUserData = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.arpuNewCustomersMetrics,
      [],
      'actual',
      'projected'
    );
  };

  public getLtvData = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.ltvMetrics,
      [],
      'actual',
      'projected'
    );
  };

  public getAvgLtData = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.ltMetrics?.map((lt) => ({
        ...lt,
        value: Math.round(lt.value * 100),
      })),
      [],
      'actual',
      'projected'
    );
  };

  public getChurnRateForQuantityOfUsers = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.churnRateInNumberMetrics,
      [],
      'actual churn rate, %',
      'projected churn rate, %'
    );
  };

  public getLtvCacCombinedData = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.ltvMetrics,
      this.dashboardData.cacActualMetrics,
      'LTV',
      'CAC'
    );
  };

  private getChurnedCustomersSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      RevenueAnalysisDashboardDataType.CHURN_USERS_SUMMARY
    );
  };

  private getExistingUsersSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      RevenueAnalysisDashboardDataType.EXISTING_USERS_SUMMARY
    );
  };

  private getNewUsersSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      RevenueAnalysisDashboardDataType.NEW_USERS_SUMMARY
    );
  };

  private getLostUsersSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      RevenueAnalysisDashboardDataType.LOST_USERS_SUMMARY
    );
  };

  private getLostMrrSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(RevenueAnalysisDashboardDataType.LOST_MRR);
  };

  private getThreeMonthAvgLostMrrSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      RevenueAnalysisDashboardDataType.THREE_MONTH_AVG_LOST_MRR
    );
  };

  private getLTSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      RevenueAnalysisDashboardDataType.LT_SUMMARY_REVENUE
    );
  };

  private getLTVSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      RevenueAnalysisDashboardDataType.LTV_SUMMARY_REVENUE
    );
  };

  private getLTVCACSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      RevenueAnalysisDashboardDataType.LTV_CAC_SUMMARY_REVENUE
    );
  };

  private getYearlyRetentionRateSummaryData =
    (): BalanceSummaryDataset | null => {
      return this.getBalanceSummaryData(
        RevenueAnalysisDashboardDataType.YEARLY_RET_RATE
      );
    };

  private getYearlyChurnRateSummaryData = (): BalanceSummaryDataset | null => {
    return this.getBalanceSummaryData(
      RevenueAnalysisDashboardDataType.YEARLY_CHURN_RATE
    );
  };

  private getRevenueGrowthData = () => {
    return this.getGrowthPercentData(
      this.dashboardData.revenueGrowthActualMetrics,
      this.dashboardData.revenueGrowthProjectedMetrics,
      'actual, %',
      'projected, %'
    );
  };

  public getNewRevenueData = () => {
    return this.getSplitSummaryData(
      RevenueAnalysisDashboardDataType.NEW_REVENUE
    );
  };

  public getChurnRevenueData = () => {
    return this.getSplitSummaryData(
      RevenueAnalysisDashboardDataType.CHURN_REVENUE
    );
  };

  public getMrrBreakdownData = () => {
    return this.getStackedBarData({
      'New Revenue, $': 'newRevenueActualMetrics',
      'Existing Revenue, $': 'newRevenueActualMetrics',
      'Churn, $': 'churnedRevenueActualMetrics',
    });
  };

  public getChurnBreakdownData = () => {
    const result = this.getStackedBarData({
      'Newbiz, $': 'newRevenueActualMetrics',
      'Upsells, $': 'totalUpsalesMetrics',
      'Downgrades, $': 'totalDowngradesMetrics',
      'Cancelations, $': 'churnedRevenueActualMetrics',
    });

    const dataWithSigns = result.data.map((item: any) => {
      return {
        ...item,
        'Downgrades, $': -item['Downgrades, $'],
        'Cancelations, $': -item['Cancelations, $'],
      };
    });
    // TODO - Move to backend, make signs come from be correctly
    return {
      ...result,
      data: dataWithSigns,
    };
  };

  public getUsersBreakdownData = () => {
    const result = this.getStackedBarData({
      'new users': 'newCustomersActualMetrics',
      'existing users': 'activeCustomersMetrics',
      'churn users': 'churnedCustomersActualMetrics',
    });

    const updatedDataWithSign = result.data.map((item: any) => {
      return {
        ...item,
        'churn users': -item['churn users'],
      };
    });

    return {
      ...result,
      data: updatedDataWithSign,
    };
  };

  public getSummaryData(
    type: RevenueAnalysisDashboardDataType,
    signType: 'percent' | 'value' = 'value'
  ): SummaryDataset | null {
    const map: Map<
      RevenueAnalysisDashboardDataType,
      keyof RevenueAnalysisDashboardData
    > = new Map([
      [
        RevenueAnalysisDashboardDataType.CHURN_USERS_SUMMARY,
        'churnedCustomersActualMetrics',
      ],
      [
        RevenueAnalysisDashboardDataType.EXISTING_USERS_SUMMARY,
        'activeCustomersMetrics',
      ],
      [
        RevenueAnalysisDashboardDataType.NEW_USERS_SUMMARY,
        'newCustomersActualMetrics',
      ],
      [RevenueAnalysisDashboardDataType.LOST_MRR, 'churnRateInRevenueMetrics'],
      [
        RevenueAnalysisDashboardDataType.THREE_MONTH_AVG_LOST_MRR,
        'churnRateInRevenueMetrics',
      ],
      [
        RevenueAnalysisDashboardDataType.LOST_USERS_SUMMARY,
        'churnRateInNumberMetrics',
      ],
      [RevenueAnalysisDashboardDataType.LT_SUMMARY_REVENUE, 'ltMetrics'],
      [RevenueAnalysisDashboardDataType.LTV_SUMMARY_REVENUE, 'ltvMetrics'],
      [
        RevenueAnalysisDashboardDataType.LTV_CAC_SUMMARY_REVENUE,
        'ltvCacMetrics',
      ],
    ] as [RevenueAnalysisDashboardDataType, keyof RevenueAnalysisDashboardData][]);

    if (!map.has(type)) {
      console.warn(`No data available for type: ${type}`);
      return null;
    }
    const lastClosedDate = getLastClosedMonth(this.endDate);
    if (type === RevenueAnalysisDashboardDataType.THREE_MONTH_AVG_LOST_MRR) {
      const result = this.getThreeMonthAverage('churnRateInRevenueMetrics');

      return {
        value: result.monthData.value || 0,
        prevMonthData: result.prevMonthData.value || 0,
        year: result?.year || lastClosedDate.year,
        month: result?.month || lastClosedDate.month,
        signType,
      };
    }

    const result = this.getHeaderData(
      map.get(type) as keyof RevenueAnalysisDashboardData
    );

    return {
      value: result.monthData.value || 0,
      prevMonthData: result.prevMonthData.value || 0,
      year: result?.year || lastClosedDate.year,
      month: result?.month || lastClosedDate.month,
      signType,
    };
  }

  private getBalanceSummaryData(
    type: RevenueAnalysisDashboardDataType
  ): BalanceSummaryDataset | null {
    const map = new Map<
      RevenueAnalysisDashboardDataType,
      keyof RevenueAnalysisDashboardData
    >([
      [RevenueAnalysisDashboardDataType.YEARLY_RET_RATE, 'yearlyChurnRate'],
      [RevenueAnalysisDashboardDataType.YEARLY_CHURN_RATE, 'yearlyChurnRate'],
    ]);

    if (!map.has(type)) {
      console.warn(`No data available for type: ${type}`);
      return null;
    }

    const key = map.get(type) as keyof RevenueAnalysisDashboardData;
    const result = this.dashboardData[key] as number;

    const value =
      type === RevenueAnalysisDashboardDataType.YEARLY_RET_RATE
        ? result !== 0
          ? `${parseFloat((1 / result).toFixed(2))}%`
          : '0%'
        : `${result}%`;

    return {
      value,
    };
  }

  public getProjectedActualData = (
    actualMetrics: DashboardDataItem[],
    projectedMetrics: DashboardDataItem[],
    actualLabel: string,
    projectedLabel: string,
    xAxis = false
  ): HistogramDataset => {
    const primaryMetrics = actualMetrics.length
      ? actualMetrics
      : projectedMetrics;
    const secondaryMetrics = actualMetrics.length
      ? projectedMetrics
      : actualMetrics;

    const actualData = this.getDateFormMonthData(primaryMetrics);

    const actualVsProjected = actualData.map((primaryMetric) => {
      const { year, month, value: primaryValue } = primaryMetric;

      const secondaryMetric = secondaryMetrics.find(
        (metric) => metric.year === year && metric.month === month
      );

      const actualValue = actualMetrics.length
        ? primaryValue
        : reverseSign(secondaryMetric?.value) || 0;
      const projectedValue = actualMetrics.length
        ? reverseSign(secondaryMetric?.value) || 0
        : primaryValue;

      return {
        date: `${year}-${month}`,
        [actualLabel]: actualValue,
        [projectedLabel]: projectedValue,
      };
    });

    const nonZeroKeys = [actualLabel, projectedLabel].filter((key) =>
      actualVsProjected.some((entry: any) => entry[key] !== 0)
    );

    return {
      data: actualVsProjected,
      keys: nonZeroKeys,
      indexBy: 'date',
      xAxis,
    };
  };

  public getGrowthPercentData = (
    actualMetrics: DashboardDataItem[],
    projectedMetrics: DashboardDataItem[],
    actualLabel: string,
    projectedLabel: string
  ) => {
    const processMetrics = (metrics: DashboardDataItem[]) => {
      return this.getDateFormMonthData(metrics).map(
        ({ year, month, value }) => ({
          x: DateTime.local(year, month, 1).toFormat('yyyy-MM'),
          y: (value / 100).toFixed(2),
        })
      );
    };

    const actualData = processMetrics(actualMetrics);
    const projectedData = processMetrics(projectedMetrics);

    const filteredWithValues = [
      { key: projectedLabel, data: projectedData },
      { key: actualLabel, data: actualData },
    ].filter((item) => item.data.length > 0);

    return {
      dataset: filteredWithValues,
      curve: 'linear',
      enablePoints: false,
      showLabels: true,
    };
  };

  public getSplitSummaryData(type: RevenueAnalysisDashboardDataType): {
    name: string;
    value: number | string;
    subValues: { title: string; value: number | string }[];
  } | null {
    const map = new Map<
      RevenueAnalysisDashboardDataType,
      { title: string; key: keyof RevenueAnalysisDashboardData }[]
    >([
      [
        RevenueAnalysisDashboardDataType.NEW_REVENUE,
        [
          {
            title: 'NEW BIZ',
            key: 'newRevenueActualMetrics' as keyof RevenueAnalysisDashboardData,
          },
          {
            title: 'UPSELLS',
            key: 'totalUpsalesMetrics' as keyof RevenueAnalysisDashboardData,
          },
        ],
      ],
      [
        RevenueAnalysisDashboardDataType.CHURN_REVENUE,
        [
          {
            title: 'CANCELATIONS',
            key: 'churnedRevenueActualMetrics' as keyof RevenueAnalysisDashboardData,
          },
          {
            title: 'DOWNGRADES',
            key: 'totalDowngradesMetrics' as keyof RevenueAnalysisDashboardData,
          },
        ],
      ],
    ]);

    const metricConfig = map.get(type);

    if (!metricConfig) {
      console.warn(`No data available for type: ${type}`);
      return null;
    }

    let mainValue = 0;
    const subValues = metricConfig.map((subMetric) => {
      const subMetricData = this.getHeaderData(subMetric.key);
      const subValue = subMetricData?.monthData?.value || 0;
      mainValue += subValue;
      return {
        title: subMetric.title,
        value: subValue,
      };
    });

    return {
      name:
        type === RevenueAnalysisDashboardDataType.NEW_REVENUE
          ? 'NEW REVENUE'
          : 'CHURN REVENUE',
      value: mainValue,
      subValues,
    };
  }

  // TODO (DM): if this function not going to be replaced with BE implementation - break this function into smaller parts
  public getStackedBarData = (categoryMetricMap: {
    [categoryName: string]: keyof RevenueAnalysisDashboardData;
  }) => {
    const collectedMetrics = Object.keys(categoryMetricMap).reduce(
      (acc, category) => {
        const metricName = categoryMetricMap[category];
        const data = this.dashboardData[metricName];

        if (!this.isDashboardDataItemArray(data)) {
          console.warn(`No valid data available for metric: ${metricName}`);
          return acc;
        }

        const metricData = this.getDateFormMonthData(
          data
        ) as DashboardDataItemWithCategory[];

        if (metricData && metricData.length > 0) {
          acc.push(...metricData.map((item) => ({ ...item, category })));
        } else {
          console.warn(`No monthly data found for metric: ${metricName}`);
        }

        return acc;
      },
      [] as DashboardDataItemWithCategory[]
    );

    const dateMap = new Map<string, { [key: string]: any }>();
    const categories = Object.keys(categoryMetricMap);
    const uniqueCategories = Array.from(new Set(categories));

    for (const metrics of collectedMetrics) {
      const { year, month, value, category } = metrics;
      const date = `${year}-${String(month).padStart(2, '0')}`;

      if (!dateMap.has(date)) {
        dateMap.set(date, {
          date,
          ...uniqueCategories.reduce((acc, cat) => {
            acc[cat] = 0;
            return acc;
          }, {} as Record<string, number>),
        });
      }

      const currentEntry = dateMap.get(date) as DateEntry;
      currentEntry[category] = value;
      dateMap.set(date, currentEntry);
    }

    const data = Array.from(dateMap.values());

    return {
      data: data,
      keys: uniqueCategories,
      indexBy: 'date',
    };
  };

  public getRevenueCurveData = () => {
    const metrics = this.dashboardData['retentionCurveInRateMetrics'] as {
      monthBucket: number;
      retentionRate: number;
    }[];

    const formattedData = metrics.map(({ monthBucket, retentionRate }) => {
      const date = DateTime.now()
        .minus({ months: monthBucket })
        .toFormat('yyyy-MM');

      return {
        x: date,
        y: (retentionRate / 100).toFixed(2),
      };
    });

    return {
      dataset: [{ key: 'retention of all users, %', data: formattedData }],
      curve: 'linear',
      enablePoints: false,
      showLabels: true,
    };
  };

  public getArpuByProductsLine = () => {
    return this.getMetricsByProductsOverTime('arpuByEachProductMetrics');
  };

  public getArpuByProducts = () => {
    return this.getMetricsByProducts('arpuByEachProductMetrics');
  };

  public getChurnRateByProducts = () => {
    const metrics = this.dashboardData[
      'yearlyChurnRateByProductMetrics'
    ] as YearlyProductDataItem[];

    const lastClosedDate = getLastClosedMonth(this.endDate);
    const data = metrics
      .filter((d) => d.value > 0 && d.product)
      .slice(0, 5) as YearlyProductDataItem[];

    if (!metrics.length) {
      return {
        data: [],
        keys: ['value'],
        indexBy: 'product',
        date: formatDate(`${lastClosedDate.year}-${lastClosedDate.month}`),
      };
    }

    return {
      data,
      keys: ['value'],
      indexBy: 'product',
      date: formatDate(`${lastClosedDate.year}-${lastClosedDate.month}`),
    };
  };

  public getLTVByProducts = () => {
    return this.getMetricsByProducts('ltvByProductsMetrics');
  };

  public getLTByProducts = () => {
    return this.getMetricsByProducts('ltByProductsMetrics');
  };

  public getMetricsByProducts = (
    dataKey: keyof RevenueAnalysisDashboardData
  ) => {
    const metrics = this.dashboardData[
      dataKey
    ] as DashboardDataItemWithProduct[];

    const { year, month } = getLastClosedMonth(this.endDate);
    const lastMonthData = (
      this.getDateFormMonthData(metrics) as DashboardDataItemWithProduct[]
    )
      .filter(
        (d) => d.year === year && d.month === month && d.value > 0 && d.product
      )
      .slice(0, 5);

    if (!lastMonthData.length) {
      return {
        data: [],
        keys: ['value'],
        indexBy: 'product',
        date: formatDate(`${year}-${month}`),
      };
    }

    const data = lastMonthData.map((d) => ({
      product: d.product,
      value: d.value,
    }));

    return {
      data,
      keys: ['value'],
      indexBy: 'product',
      date: formatDate(`${year}-${month}`),
    };
  };

  public getMetricsByProductsOverTime = (
    dataKey: keyof RevenueAnalysisDashboardData
  ) => {
    const metrics = this.dashboardData[
      dataKey
    ] as DashboardDataItemWithProduct[];
    const filteredData = this.getDateFormMonthData(
      metrics
    ) as DashboardDataItemWithProduct[];
    const groupedByProduct = filteredData.reduce(
      (acc, { product, year, month, value }) => {
        if (!product || value <= 0) return acc;

        const date = DateTime.local(year, month, 1).toFormat('yyyy-MM');
        if (!acc[product]) acc[product] = [];
        acc[product].push({ x: date, y: value.toFixed(2) });

        return acc;
      },
      {} as Record<string, { x: string; y: string }[]>
    );

    const topProducts = Object.entries(groupedByProduct)
      .sort(
        ([, a], [, b]) =>
          b.reduce((sum, item) => sum + parseFloat(item.y), 0) -
          a.reduce((sum, item) => sum + parseFloat(item.y), 0)
      )
      .slice(0, 5)
      .map(([product, data]) => ({ key: product, data }));

    return {
      dataset: topProducts,
      curve: 'linear',
      enablePoints: false,
      showLabels: false,
    };
  };

  public getCOGSHeatmapData = () => {
    const data = this.dashboardData['retentionCurveInRateByProductMetrics'] as {
      product: string;
      monthBucket: number;
      retentionRate: number;
    }[];

    const products = Array.from(new Set(data.map((item) => item.product)))
      .filter((product) => product.trim() !== '')
      .slice(0, 4);

    const monthBuckets = Array.from(
      new Set(data.map((item) => item.monthBucket))
    ).sort((a, b) => a - b);

    const dataMap = new Map<string, Map<number, number>>();
    products.forEach((product) => {
      dataMap.set(product, new Map());
    });
    data.forEach(({ product, monthBucket, retentionRate }) => {
      if (products.includes(product)) {
        dataMap.get(product)?.set(monthBucket, retentionRate);
      }
    });

    const heatmapData = products.map((product) =>
      monthBuckets.map((month) => dataMap.get(product)?.get(month) || 0)
    );

    const roundedHeatmapData = heatmapData.map((innerArray) =>
      innerArray.map((value) => Math.round(value))
    );

    return {
      data: roundedHeatmapData,
      labels: {
        products,
        months: monthBuckets.map((month) => `mon ${month}`),
      },
    };
  };

  public isDashboardDataItemArray(data: any): data is DashboardDataItem[] {
    return (
      Array.isArray(data) &&
      data.every(
        (item) =>
          typeof item === 'object' &&
          'month' in item &&
          'year' in item &&
          'value' in item
      )
    );
  }
}
